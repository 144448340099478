export default function checkElementForm(value, type='text') {

    let rgxp;
    switch (type) {
        case 'email':
            rgxp = /^[a-zA-Z0-9.]{4,}@[a-zA-Z0-9-]{3,}\.[a-zA-Z0-9-]{2,}$/;
            break;
        case 'phonenumber':
            rgxp = /^\d{9,10}$/;
            break;
        case 'message':
            rgxp = /^[\w\W]{6,}$/;
            break;
        case 'zipcode':
            rgxp = /^\d{5}$/;
            break;
        case 'siretNum':
            rgxp = /^|d{14}$/;
            break;
        case 'password':
            rgxp = /^[a-zA-Z0-9_ ]{6}$/;
            break;
        default:
            rgxp = /^[a-zA-Z0-9_ ]{2,}$/;
            break;
    }
    return !!value.match(rgxp)
}

export function isNumber(string) {
    return !isNaN(string);
  }
  

export function downloadURI(data, name) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export function fileType(fType) {
    const type = (''+fType).toLowerCase()
    let icon = '';
    if (type === 'gz' || type === '7z' || type === 'rar'  || type === 'zip') {
        icon = 'icon-archive'
    } else if (type === 'mp3' || type === 'wav' || type === 'wma'  || type === 'ogg') {
        icon = 'icon-audio'
    } else if (type === 'csv') {
        icon = 'icon-csv'
    } else if (type === 'xls' || type === 'xlsx') {
        icon = 'icon-excel'
    } else if (type === 'jpg' || type === 'jpeg' || type === 'png') {
        icon = 'icon-image'
    } else if (type === 'pst' || type === 'ost' || type === 'msg'  || type === 'eml') {
        icon = 'icon-outlook'
    } else if (type === 'pdf') {
        icon = 'icon-pdf'
    } else if (type === 'ppt' || type === 'pptx') {
        icon = 'icon-ppt'
    } else if (type === 'txt') {
        icon = 'icon-txt'
    } else if (type === 'mp4' || type === 'avi' || type === 'wmf'  || type === 'flv' || type === 'flw'|| type === 'webm' || type === 'mov') {
        icon = 'icon-video'
    } else if (type === 'doc' || type === 'docx' || type === 'odt') {
        icon = 'icon-word'
    } else if (type === 'folder') {
        icon = 'icon-fldr'
    } else if (type === 'docxf') {
        icon = 'icon-form'
    } else {
        icon = 'icon-file'
    }

    return icon
}

export function checkFileValifity(fileExt, allowedExtensions) {
    return allowedExtensions.includes(fileExt);
    // return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onloadend = function () {
    //         const arr = new Uint8Array(reader.result).subarray(0, 4);
    //         let header = ''; 
    //         for (let i = 0; i < arr.length; i++) {
    //             header += arr[i].toString(16);
    //         }

    //         console.log('File type is: ' + header);
    //         const isValid = allowedFileTypes.some(allowedHeader => header.startsWith(allowedHeader));
    //         console.log(isValid )
    //         if(!isValid){
    //             console.log('File is invalid');
    //             reject(false)
    //         }
    //         console.log('File is valid');
    //         resolve(true)
    //     };
    //     reader.readAsArrayBuffer(file);
    // })
}

export function getNameForUser(listUsers, email, group) {
    const items = listUsers.filter(item => item.email === email)
    let nameToReturn = ''

    if(items.length === 1) {
        const user = items[0],
            name = user.firstname + ' ' + user.lastname

        if (group === undefined) {
            nameToReturn = user.length === 0 ? email : name
        }

        if (Array.isArray(group)) {
            if (group.length > 1) {
                nameToReturn = user.length === 0 ? email : name.split(' ')[0]
            } else {
                nameToReturn = user.length === 0 ? email : name
            }
        }
    }

    return nameToReturn
}

export function getColorForUser(listUsers, email) {
    let user = listUsers.filter(item => item.email === email)
    return user.length === 0 ? '#00244D' : user[0].color
}

export function getObjectKey(object, key, isString = false) {
    let val = isString ? '':null

    if(isDefined(object) && object !== null) {
        val = Object.prototype.hasOwnProperty.call(object, key) ? object[key] : val
        // val = object.hasOwnProperty(key) ? object[key] : val
    }

    if(isString && val == null){
        val = ''
    }

    return val
}

export function internalLowerCase(obj){
    return typeof obj === 'string' ? obj.toLowerCase() : ''
}

export function isDefined(item){
    return typeof item !== 'undefined' && item !== null
}

export function isValidObject(obj){
    return isDefined(obj) && Object.keys(obj).length > 0
}

export function isArray(item){
    return typeof item === 'object'
}

export function buildFormData(data, method = 'POST', isJSON = false) {
    let formData = null
    const isObject = (method === 'POST' || method === 'GET') && !isJSON

    if(isObject) {
        formData = new FormData()
    } else {
        formData = {}
    }

    for (const key in data) {
        const val = data[key]

        if(val !== null && isDefined(val)) {
            if(typeof val === 'string' && val.trim() === ''){
                continue
            }

            if (isObject) {
                formData.append(key, data[key]);
            } else {
                formData[key] = data[key]
            }
        }
    }

    return formData
}

export function getRandomColor(colors) {
    let randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

export function userInitials(user){
    return initials(getObjectKey(user, 'firstname'), getObjectKey(user, 'lastname'))
}

export function initials(firstname, lastname){
    const item = firstname+' '+lastname
    return initials_(item)
}

export function initials_(fullName){
    let val = 'LL'

    if(typeof fullName === 'string' && fullName.trim() !== ''){
        const
            items = fullName.split(' '),
            len = items.length

        if(len > 1){
            val = items[0][0] +  ' ' +items[1][0]
        }else{
            val = items[0][0] + ' ' + items[0][items.length-1]
        }
    }

    return val
}

// export function filterTab(object, params){
//     return object.filter(item => {
//         return Object.keys(params).every(key =>
//             String(item[key]).toLowerCase().includes(String(params[key]).toLowerCase()))
//     })
// }

export function getFormatDate(date){
    if (date === null) return null;
    date=new Date(date)
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;
    return formattedToday
}
export function fixingNumberToDecimal(num){
    if(typeof num==='string'){
      num=parseInt(num)
    } // string
    if(num==0){
        return 0
    }
    if(num % 1===0){
        return num
    }
    if (isNaN(num)){
        return 0
    }
    return Number(num).toFixed(2)
}

export function isDefinedNumElseZero(num){
    if(num){
        return num
    }
    return 0
}

export function saveTokenExpiration(){
    const expiredAt = Date.now()
    sessionStorage.setItem('tokenExpiredAt', expiredAt)
}

export function tokenIsValid(){
    const expiredAt = sessionStorage.getItem('tokenExpiredAt')

    if(!isDefined(expiredAt) || expiredAt === null){
        return false
    }

    const delaySecond = Math.floor((Date.now() - expiredAt) / 1000 )

    return delaySecond < 3600
}

export async function dropVueStorage(){
    window.sessionStorage.clear('vuex')
}

export function calendarLabels(lang ='fr'){
    const labels = {
        fr: {
            labelPrevDecade: 'Décennie précédente',
                labelPrevYear: 'Année précédente',
                labelPrevMonth: 'Mois précédent',
                labelCurrentMonth: 'Mois courant',
                labelNextMonth: 'Mois suivant',
                labelNextYear: 'Année suivante',
                labelNextDecade: 'Décennie suivante',
                labelToday: "Ajourd'hui",
                labelSelected: 'Date sélectionnée',
                labelNoDateSelected: 'Aucune date sélectionnée',
                labelCalendar: 'Calendrier',
                labelNav: 'Navigation dans le calendrier',
                labelHelp: 'Utilisez les touches fléchées pour naviguer dans le calendrier'
        },
        de: {
            labelPrevDecade: 'Vorheriges Jahrzehnt',
                labelPrevYear: 'Vorheriges Jahr',
                labelPrevMonth: 'Vorheriger Monat',
                labelCurrentMonth: 'Aktueller Monat',
                labelNextMonth: 'Nächster Monat',
                labelNextYear: 'Nächstes Jahr',
                labelNextDecade: 'Nächstes Jahrzehnt',
                labelToday: 'Heute',
                labelSelected: 'Ausgewähltes Datum',
                labelNoDateSelected: 'Kein Datum gewählt',
                labelCalendar: 'Kalender',
                labelNav: 'Kalendernavigation',
                labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
        },
    }
    return labels[lang]
}
function isDate(item){
    return item instanceof Date
}
export function isLower(compare, seed){
    let bool = false

    if(isDate(seed) && isDate(compare)){
        bool = true
        if(compare.getFullYear() > seed.getFullYear()){
            bool = false
        }else if(compare.getFullYear() === seed.getFullYear()){
            if(compare.getMonth() > seed.getMonth()){
                bool = false
            }else if(compare.getMonth() === seed.getMonth()){
                if(compare.getDate() > seed.getDate()){
                    bool = false
                }
            }
        }
    }

    return bool
}
export function isInDate(seed, startDate, endDate){
    return isLower(startDate, seed) && isLower(seed, endDate)
}

export function isLowerCase(letter) {
    return letter.toLowerCase() === letter; 
}

export function formatString(input) {
    if (typeof input !== 'string' || input.length === 0) {
      return input;
    }
  
    const firstLetter = input.charAt(0).toUpperCase();
    const restOfString = input.slice(1).toLowerCase();
  
    return firstLetter + restOfString;
}

export function /*Date*/ parseDate(dateStr) {
    let /*Date*/ date;
    if (dateStr.indexOf('/') >= 0) {
        let t = dateStr.split('/');
        if (t.length === 3) {
            date = new Date(parseInt(t[2]), parseInt(t[1]) - 1, parseInt(t[0]));
        }
    } else if (dateStr.indexOf("-") >= 0) {
        date = new Date(dateStr);
    }
    return date;
}

export function /*String*/ convertDateFormat(dateStr) {
    if (dateStr) {
        let /*String*/ date;
        if (dateStr.indexOf("/") >= 0) {
            return dateStr.substring(0, 10)
        } else if (dateStr.indexOf("-") >= 0) {
            date = new Date(dateStr);
        }
        return dateFormattedFromDate(date);
    } else {
        return ''
    }
}

export function /*String*/ dateFormattedFrenchDate(/*String*/ date) {
    if(date)
        return date.slice(8,10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4)
    return ''
}

export function /*String*/ dateFormattedFrenchDate2(/*String*/ date) {
    if(date)
        return date.slice(0, 2) + '/' + date.slice(3, 5) + '/' + date.slice(6, 12)
    return ''
}


export function /*String*/ dateFormatted(/*String*/ date) {
    if (date !== null) {
        date = parseDate(date)
        return dateFormattedFromDate(date)
    } else {
        return ''
    }
}

export function /*Date*/ dateFormatted1(/*String*/ date) {
    if (date === null) return null;
    
     // Vérifier si la date est au format "DD-MM-YYYY" et la convertir en "YYYY-MM-DD"
     const regex = /^(\d{2})-(\d{2})-(\d{4})$/;
     const match = date.match(regex);

    if(match){
        return new Date(date.replace(regex, '$3-$2-$1'));
    }else{
        return new Date(date);
    }
    
    // previous solutions
    // return date !== null ? new Date(date) : null
}

export function /*String*/ dateFormattedFromDate(/*Date*/date,reverseCase=false) {
    // date=new Date(date)
    const day = (date.getDate() < 10 ? '0' : '') + date.getDate()
    const month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1)
    const year = date.getFullYear()
    if(reverseCase) {
        return day + '/' + month + '/' + year
    }
    return year + '/' + month + '/' + day
}

// export function /*String*/ timeFormatted(date) {
//     const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
//     const minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
//
//     return hour + ':' + minute
// }

export function /*String*/ dateForMessenger(date, time) {
    let result = ''
    date = parseDate(dateFormattedFrenchDate(date))
    const today = new Date()
    const todayGetTime = today.getTime()
    const dateGetTime = date.getTime()
    const oneDay = 24*60*60*1000
    const oneWeek = 7*24*60*60*1000
    const interval = todayGetTime - dateGetTime

    if (interval < oneDay && date.getDay() === today.getDay()) {
        result = time
    } else if (interval < (oneWeek - oneDay)  && date.getDay() === (today.getDay() - 1)) {
        result = 'hier'
    } else if (interval < oneWeek && date.getDay() !== today.getDay()) {
        const options = { weekday: 'long'};
        result = new Intl.DateTimeFormat('fr-FR', options).format(date)
    } else if (interval < oneWeek && date.getDay() === today.getDay() || interval > oneWeek) {
        result = dateFormattedFromDate(date, true)
    }

    return result
}

// export function sortConversations(arrayConversations, messagesConversation) {
//     arrayConversations.sort((a, b) =>{
//         const messagesConversationA = messagesConversation(a.slug)
//         const newDateA = (messagesConversationA.length === 0) ? a.createdAt : messagesConversationA[messagesConversationA.length - 1].createdAt

//         const messagesConversationB = messagesConversation(b.slug)
//         const newDateB = (messagesConversationB.length === 0) ? b.createdAt : messagesConversationB[messagesConversationB.length - 1].createdAt

//         return new Date(newDateB).getTime() - new Date(newDateA).getTime()
//     })

//     return arrayConversations
// }


export function sortConversations(arrayConversations, messagesConversation) {
    // Sort the remaining conversations based on the date of their last messages
    //CHATBOT
    const remainingConversations = arrayConversations.slice(1);
    remainingConversations.sort((a, b) => {
      const messagesConversationA = messagesConversation(a.slug);
      const newDateA = messagesConversationA.length === 0 ? a.createdAt : messagesConversationA[messagesConversationA.length - 1].createdAt;
  
      const messagesConversationB = messagesConversation(b.slug);
      const newDateB = messagesConversationB.length === 0 ? b.createdAt : messagesConversationB[messagesConversationB.length - 1].createdAt;
  
      return new Date(newDateB).getTime() - new Date(newDateA).getTime();
    });
  
    // Reconstruct the array with the sorted remaining conversations
    arrayConversations.splice(1, remainingConversations.length, ...remainingConversations);
  
    return arrayConversations;
  }

export function formattedPhoneNumber(arrayPhone) {
    if(arrayPhone !== null) {
        arrayPhone = arrayPhone.replaceAll(" ", "")
        return `${arrayPhone.substring(0, 2)} ${arrayPhone.substring(2, 4)} ${arrayPhone.substring(4, 6)} ${arrayPhone.substring(6, 8)} ${arrayPhone.substring(8, 10)}`
    } else {
        return ''
    }
}

export function formattedSiretNumber(arraySiret) {
    if(arraySiret !== null) {
        arraySiret = arraySiret.replaceAll(" ", "")
        return `${arraySiret.substring(0, 3)} ${arraySiret.substring(3, 6)} ${arraySiret.substring(6, 9)} ${arraySiret.substring(9, 14)}`
    } else {
        return ''
    }
}

export function sortArrayObject(objs, sortKey){
    return objs.sort((a, b) => getObjectKey(a, sortKey, true).localeCompare(getObjectKey(b, sortKey, true)))
}

export function stringIsNotBlank(item){
    return isDefined(item) && (typeof item === 'string') && item.trim() !== ''
}

export function getFullName(userList, email){
    let fullName = ''

    const usr = userList.filter(item => item.email === email)

    if(usr.length === 1){
        fullName = usr[0].fullname
    }

    return fullName
}

export function isEmptyObject(object){
    return Object.keys(object).length === 0
}

export function objectToBTList(obj){
    let items = []

    for(const item of obj) {
        items.push({
            value: getObjectKey(item, 'id'),
            text: getObjectKey(item, 'name')
        })
    }
    return items
}

export function spinAction(spinner, self) {
    if (spinner === true) {
        const spin = self.$root.$el.querySelector('#app > .e-spinner-pane')
        spin.classList.remove('e-spin-hide');
        spin.classList.add('e-spin-show');
    } else {
        const spin = self.$root.$el.querySelector('#app > .e-spinner-pane')
        spin.classList.remove('e-spin-show');
        spin.classList.add('e-spin-hide');
    }
}


export async function loadOnlyOfficeScript(url, id){
  return new Promise((resolve, reject) => {
    try {
      if (document.getElementById(id)) {
        if (window.DocsAPI) return resolve(null);

        let intervalHandler = setInterval(() => {
          if (!window.DocsAPI) return;
          clearInterval(intervalHandler);
          return resolve(null);
        }, 500);

      }else {
        const script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("id", id);

        script.onload = resolve;
        script.onerror = reject;

        script.src = url;
        script.async = true;

        document.body.appendChild(script);
      }
    } catch (e) {
      console.error(e);
    }
  });
}

export function getDocumentType(filetype) {
    const DocumentExts = ["doc", "docx", "docm","dot", "dotx", "dotm","odt", "fodt", "ott", "rtf", "txt","html", "htm", "mht", "xml","pdf", "djvu", "fb2", "epub", "xps", "oxps", "oform", "docxf"];
    const SpreadsheetExts = ["xls", "xlsx", "xlsm", "xlsb","xlt", "xltx", "xltm","ods", "fods", "ots", "csv"];
    const PresentationExts = ["pps", "ppsx", "ppsm","ppt", "pptx", "pptm","pot", "potx", "potm","odp", "fodp", "otp"];
    if (DocumentExts.includes(filetype)){
        return "word"; // word for text document extensions
    }else if (SpreadsheetExts.includes(filetype)){
        return "cell";  // cell for spreadsheet extensions
    }else if (PresentationExts.includes(filetype)){
        return "slide";  // slide for presentation extensions
    }
}

export function capitalize(string) {
    return string[0].toUpperCase() + string.slice(1);
}


export function redirectToRecordLink (link, router = null){
    const linkArr = link.split("/");
    const linkParam = linkArr[linkArr.length - 1];

    let routeName, paramName;
    switch (true) {
      case link.includes("/contact/view/"):
        routeName = "Contact View";
        paramName = "id";
        break;
      case link.includes("/company/view/"):
        routeName = "Company View";
        paramName = "id";
        break;
      case link.includes("/campagne/view/"):
        routeName = "Marketing View";
        paramName = "id";
        break;
      case link.includes("/project/view/"):
        routeName = "Project View";
        paramName = "id";
        break;
      case link.includes("/ged/file/view/"):
        routeName = "open_file";
        paramName = "slug";
        break;
      case link.includes("/ged"):
        routeName = "GED";
        break
      default:
        this.$toast.warning('Le lien est invalide')
    }

    if(!router){
        router = this.$router;
    }
    router.push({ name: routeName, params: { [paramName]: linkParam } });
}

// check uif user device is a mobile 
export function detectDevice() {
    let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return screenWidth <= 768 ? 'mobile' : 'web';
}