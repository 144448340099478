import {
    RESET_TODO,
    ADD_TODO,
    DELETE_TODO,
    UPDATE_TODO,
    SET_CATEGORY_TODO
} from "@/store/mutations-types";
import axiosRequest from "@/AuthenticatedRoute";
import { buildFormData, dateFormatted, getObjectKey, isDefined, capitalize } from "@/utils";
import { getToDosView ,getTodoTaskFormatAfterUpdate } from "@/utils/utilsTasks";
import { reverseDate } from "@/utils/timeFormatUtils"
//import {dateFormattedFrenchDate} from '@/utils'
//import { reverseDate } from "@/utils/timeFormatUtils";




const namespaced = true;

const state = {
    todos: [],
    todosLoaded: false,
    todosTeam:[],
    todosUser:[],
    todosInProject:[],
    todosExternals:[],
    allStatusOfTasks:[
        "En attente",     
        "Accepté",
        "En cours",
        "Terminé",
        "A contrôler",
        "Validé",
        "Annulé",
    ],
    categoryTodo: 1
}

const getters = {
    getTodoResponsible(state, getters, rootState) {
        return state.todos.filter(item => item.responsable.email === rootState.account.userInfos.email && item.createdBy.email === rootState.account.userInfos.email)
    },

    getTodoAssign(state, getters, rootState) {
        return state.todos.filter(item => item.responsable.email === rootState.account.userInfos.email && item.createdBy.email !== rootState.account.userInfos.email)
    },

    getTodoCreated(state, getters, rootState) {
        return state.todos.filter(item => item.responsable.email !== rootState.account.userInfos.email && item.createdBy.email === rootState.account.userInfos.email)
    },

    getTodoAll(state) {
        return state.todos
    },

}

const mutations = {
    [RESET_TODO](state) {
        state.todos = []
    },

    [ADD_TODO](state, {todos}){
        for(const itemtodo of todos){
            if(state.todos.filter(item => item.id === itemtodo.id).length === 0){
                state.todos.unshift(itemtodo)
            }
        }

        state.todosLoaded = true
    },

    [DELETE_TODO](state, {id}) {
        state.todos = state.todos.filter(item => item.id !== id)
    },

    [UPDATE_TODO](state, {todo}){
        state.todos = state.todos.map(item => item.id === todo.id ? todo : item)
    },

    [SET_CATEGORY_TODO](state, data) {
        state.categoryTodo = data
    },

    setToDoUsers(state,todosInProject){
        if(todosInProject.length===1){
            state.todosUser=getToDosView([todosInProject[0]])
        }
        else{
            state.todosUser=getToDosView(todosInProject[0])
            state.todosTeam=getToDosView(todosInProject[1])
            state.todosExternals=getToDosView(todosInProject[2])
        }
    },

    updateToDosInScheduleAfterValidation(state,internalData){
        var switchCase=internalData['switchCase']
        if(switchCase===1){
            state.todosTeam=state.todosTeam.map(element=>{
                if(element.id===internalData.id){
                    element.startDate=internalData.startDate
                    element.endDate=internalData.endDate
                    element.timeValidation='Validé'
                }
                return element
            }) 
        }
        if(switchCase===0){
            state.todosUser=state.todosUser.map(element=>{
                if(element.id===internalData.id){
                    element.startDate=internalData.startDate
                    element.endDate=internalData.endDate
                    element.timeValidation='Validé'
                }
                return element
            })
        }
    },

    updateToDosInSchedule(state,internalData){
        var switchCase=internalData['switchCase']
        //0 user ,1 team ,2 external
        if(switchCase===1){
            state.todosTeam=state.todosTeam.map(element=>{
                if(element.id===internalData.id){
                    return internalData
                }
                return element
            }) 
        }
        if(switchCase===0){
            state.todosUser=state.todosUser.map(element=>{
                if(element.id===internalData.id){
                    return internalData
                }
                return element
            })
        }
        if(switchCase===2){
            state.todosExternals=state.todosExternals.map(element=>{
                if(element.id===internalData.id){
                    return internalData
                }
                return element
            })
        } 
    },

    addToDosInState(state,todo){
        //0 user ,1 team ,2 external
        if(getObjectKey(todo,'notInGantt')){
            if(state.todosTeam.filter(element=>{
                return element.id===todo.id
            }).length===0){
                state.todosTeam.push(todo)
            }
            return
        }
        var switchCase=todo['switchCase']
        if(switchCase===1){
            if(state.todosTeam.filter(element=>{
                return element.id===todo.id
            }).length===0){
                state.todosTeam.push(todo)
            }
        }
        if(switchCase===0){
            if(state.todosUser.filter(element=>{
                return element.id===todo.id
            }).length===0){
                state.todosUser.push(todo)
            }
        } 
        if(switchCase===2){
            if(state.todosExternals.filter(element=>{
                return element.id===todo.id
            }).length===0 ){
                state.todosExternals.push(todo)
            }
        }
    },

    deleteToDosInSchedule(state,data){
        
        var switchCase=data['switchCase']
        if(switchCase===1){
            state.todosTeam=state.todosTeam.filter(element=>{
                return element.id!==data.id
            }) 
        }
        if(switchCase===0){
            state.todosUser=state.todosUser.filter(element=>{
                return element.id!==data.id
            })
        }
        if(switchCase===2){
            state.todosExternals=state.todosExternals.filter(element=>{
                return element.id!==data.id
            })
        }
            
    },

    setTodosToNull(state){
        state.todosTeam=[]
        state.todosUser=[]
    }
}

const actions = {
    //Todos
    async createTodo({rootState, commit,dispatch}, data){
        const method = "POST"
        let internalData = data
        internalData['lp_client'] = rootState.account.userInfos.lpClient.id
        let isForSchedule=false
        if(data['isForSchedule']){
            isForSchedule=data['isForSchedule']
        }
        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/todotask/create',
                headers: {
                    Authorization: 'Bearer ' + rootState.account.userInfos.token
                },
                //buildFormData is deleteing URL attr
                data: !isForSchedule?buildFormData(internalData, method, true):internalData
            }).then(response => {
                const data = response.data.data
                
                if (isDefined(data)){

                    
                    var formatedData=getTodoTaskFormatAfterUpdate(data)

                    formatedData['switchCase']=internalData['switchCase']

                    commit('addToDosInState',formatedData)
                    formatedData['startDate']=data.scheduledDate
                   
                    if(internalData['switchCase']!==2 && internalData['status']==='Validé' && internalData['percent']>0 && internalData['workload']>0){
                        dispatch('validTimeModule/addToDoTask',formatedData,{root:true})
                    }
                
                    commit(ADD_TODO, {todos: buildTodo(data, true)})
                 
                }
                
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadToDosInProject({rootState, commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                // url: '/todotask/list/'+rootState.project.current_project_slug,
                url: '/todotask/list/'+rootState.project.project.slug,
                headers: {
                    Authorization: 'Bearer ' + rootState.account.userInfos.token
                }
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit('setToDoUsers', data)
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadTodos({rootState, commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/todotask/list',
                headers: {
                    Authorization: 'Bearer ' + rootState.account.userInfos.token
                }
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_TODO, {todos: buildTodos(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadTodosAssign({rootState, commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/todotask/responsible-tasks',
                headers: {
                    Authorization: 'Bearer ' + rootState.account.userInfos.token
                }
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_TODO, {todos: buildTodos(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadTodosAssignAll({rootState, commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/todotask/search-by-responsible',
                headers: {
                    Authorization: 'Bearer ' + rootState.account.userInfos.token
                }
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_TODO, {todos: buildTodos(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadTodosCreated({rootState, commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/todotask/search-by-user',
                headers: {
                    Authorization: 'Bearer ' + rootState.account.userInfos.token
                }
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_TODO, {todos: buildTodos(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    deleteTodo({commit,dispatch}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/todotask/remove/'+data.id,
            }).then(response => {
                if(Object.prototype.hasOwnProperty.call(data, 'isForSchedule')){
                    commit('deleteToDosInSchedule',data)

                    if(data['switchCase']!==2){
                        dispatch('validTimeModule/deleteToDoTask',data,{root:true}) 
                    }
                } else{
                    commit(DELETE_TODO, {id: parseInt(data.id)})
                }              
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    setCategoryTodo({commit}, data) {
        commit(SET_CATEGORY_TODO, data)
    },

    setTodosNullInState({commit}){
        commit('setTodosToNull')
    },

    async updateTodos({commit,dispatch}, {id, form,isForSchedule=false,afterValidationCase=false}){

        const method = "PUT"
        let internalData = form
        delete internalData['project']

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/todotask/update/'+id,
                data: !isForSchedule?buildFormData(internalData, method, true):internalData
            }).then(response => {
                var data = response.data.data
                if(isForSchedule){
                    var formatedData=getTodoTaskFormatAfterUpdate(data)

                    formatedData['switchCase']=internalData['switchCase']

                    if(afterValidationCase){
                        var tmp={id:formatedData.id}
                        tmp['startDate']=reverseDate(data.scheduledDate)
                        tmp['endDate']=reverseDate(data.deadline)
                        tmp['switchCase']=internalData['switchCase']
                        commit('updateToDosInScheduleAfterValidation',tmp)
                    }
                    else{
                        commit('updateToDosInSchedule',formatedData)
                    }


                    var formatedDataCopy = Object.assign({}, formatedData);

                    if(formatedData['switchCase']!==2 && formatedDataCopy['status']==='Validé' && data['percent']>0 && data['workload']>0){
                        //we are adding to do task to validmodule if the conditions are valid
                        dispatch('validTimeModule/addToDoTask',formatedDataCopy,{root:true})
                        dispatch('validTimeModule/updateToDoTask',formatedDataCopy,{root:true}) 
                    } 
                }

                if (isDefined(data) && !isForSchedule) {
                    commit(UPDATE_TODO, {todo: buildTodo(data)})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}

function buildTodos(data){
    let result = []

    for(const item of data){
        result.push(buildTodo(item))
    }
    return result
}

export function buildTodo(item, returnArray = false){
    let result = null

    if(isDefined(item)){
        result = {
            "createdAt": dateFormatted(getObjectKey(item, 'createdAt')),
            "deadline": dateFormatted(getObjectKey(item, 'deadline')),
            "id": getObjectKey(item, 'id'),
            "minutesWorked": getObjectKey(item, 'minutesWorked'),
            "percent": getObjectKey(item, 'percent'),
            "relationType": getObjectKey(item, 'relationType'),
            "scheduledDate": dateFormatted(getObjectKey(item, 'scheduledDate')),
            "status": getObjectKey(item, 'status'),
            "summary": getObjectKey(item, 'summary', true),
            "timeValidation": getObjectKey(item, 'timeValidation'),
            "title": getObjectKey(item, 'title'),
            "type": getObjectKey(item, 'type'),
            "url": getObjectKey(item, 'url'),
            "workload": getObjectKey(item, 'workload'),
        }

        if(isDefined(item.project)){
            result['project'] = {
                'codeID': getObjectKey(item.project, 'codeID'),
                'name': getObjectKey(item.project, 'name').toUpperCase(),
            }
        } else {
            result['project']= []
        }

        if(isDefined(item.team)){
            result['team'] = {
                'id': getObjectKey(item.team, 'id'),
                'name': getObjectKey(item.team, 'name'),
            }
        } else {
            result['team']= []
        }

        if(isDefined(item.responsable)){
            result['responsable'] = {
                'id': getObjectKey(item.responsable, 'id'),
                'email': getObjectKey(item.responsable, 'email'),
                'firstname': capitalize(getObjectKey(item.responsable, 'firstname')),
                'lastname': getObjectKey(item.responsable, 'lastname').toUpperCase(),
                'name': capitalize(getObjectKey(item.responsable, 'firstname')) + ' ' + getObjectKey(item.responsable, 'lastname').toUpperCase(),
            }
        } else {
            result['responsable']= []
        }

        if(isDefined(item.createdBy) && item.createdBy !== null){
            result['createdBy']= {
                'id': getObjectKey(item.createdBy, 'id'),
                'firstname': capitalize(getObjectKey(item.createdBy, 'firstname')),
                'lastname': getObjectKey(item.createdBy, 'lastname').toUpperCase(),
                'name': capitalize(getObjectKey(item.createdBy, 'firstname')) + ' ' + getObjectKey(item.createdBy, 'lastname').toUpperCase(),
                'email': getObjectKey(item.createdBy, 'email'),
                'is_employee': getObjectKey(item.createdBy, 'is_employee'),
            }
        }else{
            result['created_by'] = null
        }

        if(isDefined(item.lp_client) && item.lp_client !== null){
            result['lp_client']= {
                'id': getObjectKey(item.lp_client, 'id'),
                'name': getObjectKey(item.lp_client, 'name')
            }
        }else{
            result['lp_client'] = null
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}