import {
    ADD_COMPANY,
    ADD_CONTACT,

    ADD_DISCIPLINE,
    ADD_INTEREST,
    ADD_MARKETING_CAMPAIGN,
    ADD_MARKETING_CAMPAIGN_CONTACT,

    SET_MARKETING_CAMPAING_CONTACT,
    SET_COMPANIES_FULLY_LOADED,
    SET_CONTACTS_FULLY_LOADED,
    SET_MARKETING_FULLY_LOADED,

    DELETE_COMPANY,
    DELETE_CONTACT,
    DELETE_MARKETING_CAMPAIGN,

    DROP_CONTACTS_STORE,

    READ_COMPANY,
    REMOVE_COMPANY,
    READ_CONTACT,
    REMOVE_CONTACT,
    READ_MARKETING,
    REMOVE_MARKETING,

    REMOVE_SELECTED_CONTACT,
    RESET_SELECTED_CONTACTS,

    UPDATE_CAMPAIGN,
    UPDATE_COMPANY,
    UPDATE_CONTACT,
    UPDATE_MARKETING_CAMPAIGN,
    UPDATE_SELECTED_CONTACTS,

    TOGGLE_COMPANIES_LOADER,
    TOGGLE_CONTACTS_LOADER,
    TOGGLE_MARKETING_LOADER
} from "@/store/mutations-types";
import axiosRequest from "@/AuthenticatedRoute";
import { buildFormData, getObjectKey, isDefined, dateFormatted, capitalize } from "@/utils";
import { ADD_EVENT, DELETE_EVENT } from "../mutations-types";
import { buildLawyer } from "./lawyer";
import { stringIsNotBlank } from "@/utils";

const namespaced = true;

const state = {
    // Companies variables
    companies: [],
    company: [],
    companiesCurrentPage: 1,
    companiesTotalPages: 1,
    companiesLoading : false,
    companiesFullyLoaded: false,
   
    // Contacts variables
    contacts: [],
    contact: [],
    contactsCurrentPage: 1,
    contactsTotalPages: 1,
    contactsLoading : false,
    contactsFullyLoaded: false,
    selectedContacts: [],
   
    // Marketing variables
    marketingCampaigns: [],
    marketingCampaign: [],
    marketingActions: [],
    marketingCampaignsContacts:[],
    marketingCampaignsCurrentPage: 1,
    marketingCampaignsTotalPages: 1,
    marketingCampaignsLoading : false,
    marketingCampaignsFullyLoaded: false,
   
   
    laywers: [],
    disciplines: [],
    interests: [],
    events: [],
    eventReminders: [],
}

const getters = {

    getContactsForRecomendation(state){
        let temp=state.contacts.filter(element=>{
            return element.firstname!=='***********'    
        })
        return temp.map(element => {
            element['category'] = 'Contacts ouverts'
            element['fullname'] = element.firstname + " " + element.lastname
            return element
        })
    },

    getContacts: state => {
        return state.contacts
    },

    getContactsView:state=>{
        return state.contacts.map((element)=> ({adress:element.adress,postcode:element.postcode,email:element.email,name :element.firstname,lastname:element.lastname,city:element.city,companyName:element.company.name}))
    }
}

const mutations = {
    //Company
    [ADD_COMPANY](state, {companies}){
        
        for(const company of companies){
            if(state.companies.filter(item => item.id === company.id).length === 0){
                state.companies.push(company)
            }
        }
    },

    [UPDATE_COMPANY](state,{company}){
        state.company = company
        state.companies = state.companies.map(item => item.id === company.id ? company:item)
    },

    [DELETE_COMPANY](state, {id}){
        state.companies = state.companies.filter(item => item.id !== id)
    },

    [READ_COMPANY](state, {data}){
        state.company = data
    },

    [REMOVE_COMPANY](state){
        state.company = []
    },

    [TOGGLE_COMPANIES_LOADER](state){
        state.companiesLoading = !state.companiesLoading;
    },

    [SET_COMPANIES_FULLY_LOADED](state){
        state.companiesFullyLoaded = true;
    },


    //Contact
    [READ_CONTACT](state, {data}) {
        state.contact = data;
    },

    [REMOVE_CONTACT](state){
        state.contact = []
    },

    [ADD_CONTACT](state, {contacts}){
        for(const contact of contacts){
            if(state.contacts.filter(item => item.id === contact.id).length === 0){
                state.contacts.push(contact)
            }
        }
    },


    [UPDATE_CONTACT](state,{contact}){
        state.contact = contact;
        state.contacts = state.contacts.map(item => item.id === contact.id ? contact:item)
    },

    [UPDATE_SELECTED_CONTACTS](state, object){
        // state.selectedContacts = [] ??
        // for(const contact of contacts){
        //     if(state.selectedContacts.filter(contact_id=>contact.id===contact_id).length===0){
        //         state.selectedContacts.push(contact.id) 
        //     }
        // }
        if(object.contacts.length>0){
            state.selectedContacts=object.contacts
        }
        if(object.emptyCase){
            state.selectedContacts=[]
        }
     
    },

    [REMOVE_SELECTED_CONTACT](state, id){
        state.selectedContacts = state.selectedContacts.filter(item => item !== id)
    },

    [RESET_SELECTED_CONTACTS](state){
      state.selectedContacts = []
    },

    [TOGGLE_CONTACTS_LOADER](state){
        state.contactsLoading = !state.contactsLoading;
    },

    [SET_CONTACTS_FULLY_LOADED](state){
        state.contactsFullyLoaded = true;
    },

    //Campaign
    [ADD_MARKETING_CAMPAIGN](state, {marketingCampaigns}){
        // state.marketingCampaigns = []
        for(const marketingCampaign of marketingCampaigns){
            if(state.marketingCampaigns.filter(item => item.id === marketingCampaign.id).length === 0){
                state.marketingCampaigns.push(marketingCampaign)
            }
        }

        // state.marketingCampaignsLoaded = true
    },

    [SET_MARKETING_CAMPAING_CONTACT](state,campaigns){
        state.marketingCampaignsContacts=campaigns
    },

    [ADD_MARKETING_CAMPAIGN_CONTACT](state, {marketingCampaigns}){
        
        for(const marketingCampaign of marketingCampaigns){
            if(state.marketingCampaignsContacts.filter(item => item.name === marketingCampaign.name).length === 0){
                state.marketingCampaignsContacts.push(marketingCampaign)
            }
        }

        // state.marketingCampaignsLoaded = true
    },

    [UPDATE_CAMPAIGN](state, {marketingCampaigns}) {
        state.marketingCampaigns = state.marketingCampaigns.map(item => item.id === marketingCampaigns.id ? marketingCampaigns:item)
    },

    [UPDATE_MARKETING_CAMPAIGN](state, {marketingCampaigns}) {
        state.marketingCampaigns.filter(campaign => campaign.id === marketingCampaigns.id).map(campaign => {
            campaign.Contacts = marketingCampaigns.Contacts
        })
    },

    [DELETE_MARKETING_CAMPAIGN](state, {id}){
        state.marketingCampaigns = state.marketingCampaigns.filter(item => item.id !== id)
    },

    [READ_MARKETING](state, {data}){
        state.marketingCampaign = data
    },

    [REMOVE_MARKETING](state){
        state.marketingCampaign = []
    },

    [TOGGLE_MARKETING_LOADER](state){
        state.marketingCampaignsLoading = !state.marketingCampaignsLoading;
    },

    [SET_MARKETING_FULLY_LOADED](state){
        state.marketingCampaignsFullyLoaded = true;
    },


    //Discipline
    [ADD_DISCIPLINE](state, {disciplines}){
        for(const discipline of disciplines){
            if(state.disciplines.filter(item => item.id === discipline.id).length === 0){
                state.disciplines.push(discipline)
            }
        }
    },


    //Interest
    [ADD_INTEREST](state, {interests}){
        for(const interest of interests){
            if(state.interests.filter(item => item.id === interest.id).length === 0){
                state.interests.push(interest)
            }
        }
    },

    [DROP_CONTACTS_STORE](state){
        state.contacts = []
    },

    //EVents
    [ADD_EVENT](state, {events}){
        for (const event of events){
            if(state.events.filter(item => item.id === event.id).length===0){
                state.events.push(event)
            }else{
                //here we should update
            }
        }
    },
    [DELETE_EVENT](state, {id}){
        if(state.events.filter(item => item.id === id).length === 1){
            state.events = state.events.filter(item => item.id !== id)
        }
    }
}

const actions = {

    //Company
    async createCompany({rootState, commit}, data){
        const method = "POST"

        let internalData = data
        internalData['lp_client'] = rootState.account.userInfos.lpClient.id

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/company/create',
                data: buildFormData(internalData, method, true)
            }).then(response => {
                const data = response.data.data
                if (isDefined(data)) {
                    commit(ADD_COMPANY, {companies: buildCompany(data, true)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async updateCompany({rootState, commit, dispatch}, {id, form, avatar}) {
        const method= "PUT"
        let internalData = form
        delete internalData.created_at
        internalData['lp_client'] = rootState.account.userInfos.lpClient.id

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/company/update/'+id,
                data: buildFormData(internalData, method, true)
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(UPDATE_COMPANY, {company: buildCompany(data)})
                }

                if(stringIsNotBlank(avatar)){
                    dispatch('uploadImageCompany', {id, avatar})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async deleteCompany({commit}, {id}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/company/delete/'+id,
            }).then(response => {
                commit(DELETE_COMPANY, {id})
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // async loadCompanies({commit, dispatch}){
    //     return new Promise((resolve, reject) => {
    //         axiosRequest({
    //             method: 'POST',
    //             url: '/company/list',
    //         }).then(response => {
    //             const data = response.data.result

    //             if (isDefined(data)) {
    //                 commit(ADD_COMPANY, {companies: buildCompanies(data)})
    //             }

    //             //TODO: find a righ way to load this data
    //             dispatch('loadEvents')

    //             resolve(response)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    async loadCompanies({commit}){
        commit(TOGGLE_COMPANIES_LOADER)
        if(state.companiesCurrentPage <= state.companiesTotalPages && !state.companiesFullyLoaded){
            // calculate skip and take
            const take = 15;
            const skip = (state.companiesCurrentPage - 1) * take;

            return new Promise((resolve, reject) => {
                axiosRequest({
                    method: 'POST',
                    url: `/company/list`,
                    data: { 
                        requiresCounts: true ,
                        skip: skip, 
                        take: take
                    }
                }).then(async response => {
                    let data = []
                    if(isDefined(response.data.result)){
                        data = buildCompanies(response.data.result)
                    }

                    state.companiesTotalPages = Math.ceil(+response.data.count / take);
                
                    if (isDefined(data)) {
                        await commit(ADD_COMPANY, {companies: data})
                    }

                    if(state.companiesTotalPages === state.companiesCurrentPage) commit(SET_COMPANIES_FULLY_LOADED);
                    state.companiesCurrentPage++;
    
                    resolve(data)
                }).catch(error => {
                    reject(error)
                }).finally(() => {
                    commit(TOGGLE_COMPANIES_LOADER)
                })
            })
        }
    },

    async getCompany({commit}, {id}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/company/view/'+id,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(READ_COMPANY, {data: buildCompany(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    setCompanyFromUserInfos({commit, rootState}, {id}) {
        if (isDefined(id)) {
            let data = rootState.account.userInfos.lpClient
            commit(READ_COMPANY, { data: data })
        }
    },

    removeCompany({commit}) {
        commit(REMOVE_COMPANY)
    },

    async uploadImageCompany({commit}, {id, avatar}){
        const method = "PUT"

        const newData = {}
        newData.avatar = avatar

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/company/update-avatar/'+id,
                data: newData
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(UPDATE_COMPANY, {company: buildCompany(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    //Contact
    async createContact({rootState, commit}, data){
        const method = "POST"

        let internalData = data
        internalData['lp_client'] = rootState.account.userInfos.lpClient.id

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/contact/create',
                data: buildFormData(internalData, method, true)
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    if (data.is_lawyer === true) {
                        commit('lawyer/lawyer/ADD_LAWYER', {lawyers: buildLawyer(data, true)}, { root: true })
                    }

                    commit(ADD_CONTACT, {contacts: buildContact(data, rootState.account.userInfos, rootState.lawyer.lawyers, true)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateSelectedContacts({commit}, data) {
        commit(UPDATE_SELECTED_CONTACTS, data)
    },

    removeSelectedContact({commit}, id) {
        commit(REMOVE_SELECTED_CONTACT, id)
    },

    resetSelectedContacts({commit}) {
        commit(RESET_SELECTED_CONTACTS)
    },

    async updateContact({rootState, commit, dispatch}, {id, form, avatar}){
        const method = "PUT"
        let internalData = form
        // internalData['lp_client'] = rootState.account.userInfos.lpClient.id

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/contact/update/'+id,
                data: buildFormData(internalData, method, true)
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(UPDATE_CONTACT, { contact: buildContact(data, rootState.account.userInfos) })
                    commit(READ_CONTACT, { data: buildContact(data, rootState.account.userInfos) })
                }

                if(stringIsNotBlank(avatar)){
                    dispatch('uploadImage', {id, avatar})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async deleteContact({commit}, {id}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/contact/delete/'+id,
            }).then(response => {
                commit(DELETE_CONTACT, {id})
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadContacts({ commit, rootState }, { paginate =true}) {
       
        // const companyId=rootState.account.company?'/'+rootState.account.company.id:''
        // return new Promise((resolve, reject) => {
        //     axiosRequest({
        //         method: 'GET',
        //         url: '/contact/list'+companyId,
        //     }).then(response => {
        //         const data = response.data

        //         if (isDefined(data)) {
        //             commit(DROP_CONTACTS_STORE);
        //             commit(ADD_CONTACT, {contacts: buildContacts(data, rootState.account.userInfos, rootState.lawyer.lawyers)});
        //         }
        //         resolve(response)
        //     }).catch(error => {
        //         reject(error)
        //     })
        // })

        commit(TOGGLE_CONTACTS_LOADER)
        if(state.contactsCurrentPage <= state.contactsTotalPages && !state.contactsFullyLoaded){
           
            // calculate skip and take
            const take = 15;
            const skip = (state.contactsCurrentPage - 1) * take;

            let body = {
                requiresCounts: true,
                skip: skip,
                take: take
            }

            if(!paginate){
                body = { requiresCounts: true }
            }

            return new Promise((resolve, reject) => {
                axiosRequest({
                    method: 'POST',
                    url: `/contact/list`,
                    data: body
                }).then(async response => {
                    let data = []
                  
                    if(isDefined(response.data.result)){
                        data = buildContacts(response.data.result, rootState.account.userInfos, rootState.lawyer.lawyers)
                    }

                    if(paginate){
                        state.contactsTotalPages = Math.ceil(+response.data.count / take);
                
                        if (isDefined(data)) {
                            await commit(ADD_CONTACT, {contacts: data})
                        }

                        if(state.contactsTotalPages === state.contactsCurrentPage) commit(SET_CONTACTS_FULLY_LOADED);
                        state.contactsCurrentPage++;
                    }else{
                        if (isDefined(data)) {
                            await commit(ADD_CONTACT, {contacts: data})
                        }
                    }
                    

                    resolve(data)
                }).catch(error => {
                    reject(error)
                }).finally(() => {
                    commit(TOGGLE_CONTACTS_LOADER)
                })
            })
        }
    },


    async getContact({commit, rootState}, {id}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/contact/view/'+id,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(READ_CONTACT, { data: buildContact(data, rootState.account.userInfos) })
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    setContactFromListUsers({commit, rootState}, {id}) {
        if (isDefined(id)) {
            let data = rootState.account.listUsers.filter(item => item.id === id)[0]
            commit(READ_CONTACT, { data: data })
        }
    },

    removeContact({commit}) {
        commit(REMOVE_CONTACT)
    },

    //eslint-disable-next-line
    async openUserAccess({}, data){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: `/contact/open-user-access`,
                data: data
            })
            .then(async response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    async uploadImage({commit, rootState}, {id, avatar}){
        const method = "PUT"

        const newData = {}
        newData.avatar = avatar

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/contact/update-avatar/'+id,
                data: newData
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(UPDATE_CONTACT, {contact: buildContact(data, rootState.account.userInfos)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async filterAll({commit, rootState},filtersValues){
            return new Promise((resolve, reject) => {
                axiosRequest({
                    method: 'POST',
                    url: '/contact/listFilters',
                    data : { genericFilters : filtersValues }
                }).then(response => {
                    const data = response.data.data

                    if (isDefined(data)) {
                        commit(DROP_CONTACTS_STORE);
                        commit(ADD_CONTACT, {contacts: buildContacts(data, rootState.account.userInfos)});
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
    },

    async sendSelectedContactsIds(selectedContactsIds){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/contact/getSelectedContacts',
                data : { selectedContacts : selectedContactsIds }
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    //Campaign
    async createCampaign({commit, dispatch}, data){
        const method = "POST"

        let internalData = data

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/campagne/create',
                data: buildFormData(internalData, method),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                const data = response.data.data
                this.selectedContacts = []

                if (isDefined(data)) {
                    commit(ADD_MARKETING_CAMPAIGN, {marketingCampaigns: buildCampaign(data, true)})
                    //2
                    //  commit(ADD_MARKETING_CAMPAIGN_CONTACT, {marketingCampaigns: buildCampaign(data, true)})
                }

                dispatch('loadCampaigns')

                const lawyerOfContacts = []

                for(let contact of data.Contacts) {
                    for(let lawyer of contact.lawyers) {
                        if(lawyerOfContacts.filter(item => item.email === lawyer.email).length === 0){
                            lawyerOfContacts.push(lawyer)
                        }
                    }
                }

                dispatch('account/listGlobalNotifications', null, {root:true})

                dispatch('resetSelectedContacts')

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async updateAscentCampaign({commit, dispatch}, data){
        const method = "POST"

        let internalData = data

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/campagne/return/',
                data: buildFormData(internalData, method, true),
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                // } => verify it's need json or formData
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(UPDATE_MARKETING_CAMPAIGN, {marketingCampaigns: buildCampaign(data, true)})
                }

                dispatch('loadCampaigns')

                dispatch('account/listGlobalNotifications', null, {root:true})

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadCampaigns({commit}){
        // return new Promise((resolve, reject) => {
        //     const method = "GET"

        //     axiosRequest({
        //         method: method,
        //         url: '/campagne/list',
        //     }).then(response => {
        //         const data = response.data.data

        //         if (isDefined(data)) {
        //             commit(ADD_MARKETING_CAMPAIGN, {marketingCampaigns: buildCampaigns(data)})
        //         }

        //         resolve(response)
        //     }).catch(error => {
        //         reject(error)
        //     })
        // })
        commit(TOGGLE_MARKETING_LOADER)
        if(state.marketingCampaignsCurrentPage <= state.marketingCampaignsTotalPages && !state.marketingCampaignsFullyLoaded){
            // calculate skip and take
            const take = 15;
            const skip = (state.marketingCampaignsCurrentPage - 1) * take;

            return new Promise((resolve, reject) => {
                axiosRequest({
                    method: 'POST',
                    url: `/campagne/list`,
                    data: { 
                        requiresCounts: true ,
                        skip: skip, 
                        take: take
                    }
                }).then(async response => {
                    let data = []

                    if(isDefined(response.data.result)){
                        data = buildCampaigns(response.data.result)
                    }

                    state.marketingCampaignsTotalPages = Math.ceil(+response.data.count / take);
                
                    if (isDefined(data)) {
                        await commit(ADD_MARKETING_CAMPAIGN, {marketingCampaigns: data})
                    }

                    if(state.marketingCampaignsTotalPages === state.marketingCampaignsCurrentPage) commit(SET_MARKETING_FULLY_LOADED);
                    state.marketingCampaignsCurrentPage++;
    
                    resolve(data)
                }).catch(error => {
                    reject(error)
                }).finally(() => {
                    commit(TOGGLE_MARKETING_LOADER)
                })
            })
        }
    },

    async loadContactCampaigns({commit}, {id}){
        
        return new Promise((resolve, reject) => {
            const method = "GET"

            axiosRequest({
                method: method,
                url: '/campagne/contact/list/'+ id,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_MARKETING_CAMPAIGN, {marketingCampaigns: buildCampaigns(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadContactCampaigns2({commit}, {id}){
        
        return new Promise((resolve, reject) => {
            const method = "GET"

            axiosRequest({
                method: method,
                url: '/campagne/contact/list2/'+ id,
            }).then(response => {
                const data = response.data.data
             
                if (isDefined(data)) {
                    //commit(ADD_MARKETING_CAMPAIGN_CONTACT, {marketingCampaigns: buildCampaigns(data)})
                    commit(SET_MARKETING_CAMPAING_CONTACT,buildCampaigns(data))
                }
                if(response.data.status===1 && !isDefined(data)){
                    commit(SET_MARKETING_CAMPAING_CONTACT,[])
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async deleteCampaign({commit}, {id}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/campagne/remove/'+id,
            }).then(response => {
                commit(DELETE_MARKETING_CAMPAIGN, {id})

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async updateCampaign({commit}, data){
        const method= "PUT"
        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/campagne/update/'+data.id,
                data: buildFormData(data.form, method)
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(UPDATE_CAMPAIGN, {marketingCampaigns: buildCampaign(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async getCampaign({commit}, {id}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/campagne/view/'+id,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(READ_MARKETING, { data: data })
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    removeCampaign({commit}) {
        commit(REMOVE_MARKETING)
    },

    //Discipline
    async loadDisciplines({commit, state}){
        if(state.disciplines.length === 0) {
            return new Promise((resolve, reject) => {
                const method = "GET"

                axiosRequest({
                    method: method,
                    url: '/campagne/discipline/list',
                }).then(response => {
                    const data = response.data.data

                    if (isDefined(data)) {
                        commit(ADD_DISCIPLINE, {disciplines: buildDisciplines(data)})
                    }

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },

    async createDiscipline({commit}, data){
        const method = "POST"

        let internalData = data

        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/campagne/discipline/create',
                data: buildFormData(internalData, method),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_DISCIPLINE, {disciplines: buildDisciplines([data])})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    /**
     * Interests actions
     */
    async loadInterests({commit, state}){
        if(state.interests.length === 0) {
            return new Promise((resolve, reject) => {
                const method = "GET"

                axiosRequest({
                    method: method,
                    url: '/interest/search',
                }).then(response => {
                    const data = response.data.data

                    if (isDefined(data)) {
                        commit(ADD_INTEREST, {interests: buildInterests(data)})
                    }

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },

    async addInterest({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            const method = "POST"

            const newData = {}
            newData.label = data.label

            axiosRequest({
                method: method,
                url: '/interest/add/'+data.id,
                data: newData,
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    if (isDefined(data)) {
                        commit(UPDATE_CONTACT, {contact: buildContact(data, rootState.account.userInfos)})
                        commit(READ_CONTACT, { data: buildContact(data, rootState.account.userInfos) })
                    }

                    commit(ADD_INTEREST, {interests: buildInterests(data.interests)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async removeInterest({commit, rootState}, {contactId, tagId}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/interest/remove/'+ contactId + '/' + tagId,
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    if (isDefined(data)) {
                        commit(UPDATE_CONTACT, {contact: buildContact(data, rootState.account.userInfos)})
                        commit(READ_CONTACT, { data: buildContact(data, rootState.account.userInfos) })
                    }
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    /**
     * Events actions
     */

    async loadEvents({commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/event/list',
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_EVENT, {events: buildEvents(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async createEvent({commit}, data) {
        return new Promise((resolve, reject) => {
            const method = "POST"
            axiosRequest({
                method,
                url: '/event/create/',
                data: buildFormData(data, method, true),
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    if (isDefined(data)) {
                        commit(ADD_EVENT, {events: buildEvent(data, true)})
                    }
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async removeEvent({commit, rootState}, {id}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/event/delete/'+ id,
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    if (isDefined(data)) {
                        commit(DELETE_EVENT, {id: buildContact(data, rootState.account.userInfos)})
                    }
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
}

function buildCompanies(data){
    let result = []

    for(const item of data){
        result.push(buildCompany(item))
    }

    return result
}

function buildCompany(item, returnArray = false){
    let result = null

    if(isDefined(item)){
        const conditionType = item.is_customer ? 'Client' : ( item.is_cabinet ? 'Cabinet' : ( item.is_prospect ? 'Prospect' :( item.is_supplier ? 'Fournisseur': '')))

        result = {
            "id": parseInt(getObjectKey(item, 'id')),
            "name": getObjectKey(item, 'name').toUpperCase(),
            "email": getObjectKey(item, 'email'),
            "address": getObjectKey(item, 'address'),
            "additional_address": getObjectKey(item, 'additional_address'),
            "city": getObjectKey(item, 'city') ? getObjectKey(item, 'city').toUpperCase() : null,
            // "country": {
            //         'id': getObjectKey(item.country, 'id'),
            //         'label': getObjectKey(item.country, 'label'),
            //         'iso_code': getObjectKey(item.country, 'iso_code'),
            // },
            "postcode": getObjectKey(item, 'postcode'),
            "office_phone": getObjectKey(item, 'office_phone', true),
            "fax_number": getObjectKey(item, 'fax_number', true),
            "website": getObjectKey(item, 'website'),
            "siret": getObjectKey(item, 'siret'),
            "vat": getObjectKey(item, 'vat'),
            "nace_code": getObjectKey(item, 'nace_code'),
            "intitule": getObjectKey(item, 'intitule'),
            "avatar": getObjectKey(item, 'avatar'),
            "is_prospect": getObjectKey(item, 'is_prospect'),
            "is_customer": getObjectKey(item, 'is_customer'),
            "is_supplier": getObjectKey(item, 'is_supplier'),
            "is_cabinet": getObjectKey(item, 'is_cabinet'),
            "created_at": getObjectKey(item, 'created_at'),
            "type": conditionType,
            "size": getObjectKey(item, 'size'),
            "social_networks": getObjectKey(item, 'social_networks')
        }

        result['country']={
            id:item.country.id,
            label:item.country.label,
            iso_code:item.country.iso_code
        }
        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildContacts(data, connectedUser, listLawyers){
    let result = []
    for(const item of data){
        result.push(buildContact(item, connectedUser, listLawyers))
    }

    return result
}

function buildContact(item, connectedUser, listLawyers, returnArray = false){
    let result = null

    if(isDefined(item)){
        const condition = 
            (isDefined(item.responsible) && item.responsible.email === connectedUser.email) || 
            item.responsible_email === connectedUser.email || 
            item.is_lawyer === true ||
            connectedUser.speciality.toLowerCase() === 'marketing'
        const conditionType = item.is_client ? 'Client' : ( item.is_partner ? 'Partenaire' : ( item.is_prospect ? 'Prospect' :( item.is_supplier ? 'Fournisseur': '')))

        result = {
            'additional_address': condition ? getObjectKey(item, 'additional_address') : "**********",
            'address': condition ? getObjectKey(item, 'address') : "**********",
            'avatar': condition ? getObjectKey(item, 'avatar') : "**********",
            'city': condition ? getObjectKey(item, 'city').toUpperCase() : "**********",
            'civility': condition ? getObjectKey(item, 'civility') : "**********",
            'country': condition ? getObjectKey(item, 'country') : "**********",
            'created_at': getObjectKey(item, 'created_at'),
            'email': condition ? getObjectKey(item, 'email') : "***********",
            'firstname': condition ? capitalize(getObjectKey(item, 'firstname')) : "***********",
            'function': getObjectKey(item, 'function'),
            'id': parseInt(getObjectKey(item, 'id')),
            'is_client': getObjectKey(item, 'is_client'),
            'is_lawyer': getObjectKey(item, 'is_lawyer'),
            'is_partner': getObjectKey(item, 'is_partner'),
            'is_prospect': getObjectKey(item, 'is_prospect'),
            'is_supplier': getObjectKey(item, 'is_supplier'),
            'lastname': condition ? getObjectKey(item, 'lastname').toUpperCase() : "***********",
            'lawyer': getObjectKey(item, 'lawyer'),
            'mobile_phone': condition ? getObjectKey(item, 'mobile_phone', true) : "**********",
            'name': condition ? capitalize(getObjectKey(item, 'firstname'))  + ' ' + getObjectKey(item, 'lastname').toUpperCase() : "***********",
            'office_phone': condition ? getObjectKey(item, 'office_phone', true) : "**********",
            'origin': getObjectKey(item, 'origin'),
            'postcode': condition ? getObjectKey(item, 'postcode') : "**********",
            'quality': getObjectKey(item, 'quality'),
            'social_networks': [],
            'type': conditionType
        }

        if(isDefined(item.company) && item.company !== null){
            result['company']= {
                'id': getObjectKey(item.company, 'id'),
                'name': getObjectKey(item.company, 'name').toUpperCase()
            }
        }

       
        if(!Object.prototype.hasOwnProperty.call(result, 'company') && item.company_id !== undefined && item.company_name !== undefined){
            result['company']= {
                'id': getObjectKey(item, 'company_id'),
                'name': getObjectKey(item, 'company_name')
            }
        }

        if(isDefined(item.social_networks)){
            result['social_networks'] = []
            for(const social_network of item.social_networks) {
                result['social_networks'].push(
                    {
                        'id': getObjectKey(social_network, 'id'),
                        'name': getObjectKey(social_network, 'name'),
                        'link': getObjectKey(social_network, 'link'),
                    }
                )
            }
        }else{
            result['social_networks'] = []
        }

        if(isDefined(item.interests)){
            result['interests'] = []
            for(const interest of item.interests) {
                result['interests'].push(
                    {
                        'id': condition ? getObjectKey(interest, 'id') : "**********",
                        'label': condition ? getObjectKey(interest, 'label') : "**********",
                    }
                )
            }
        }else{
            result['interests'] = null
        }

        if(isDefined(item.responsible) && item.responsible !== null){
            result['responsible']= {
                'id': getObjectKey(item.responsible, 'id'),
                'firstname': capitalize(getObjectKey(item.responsible, 'firstname')),
                'lastname': getObjectKey(item.responsible, 'lastname').toUpperCase(),
                'name': capitalize(getObjectKey(item.responsible, 'firstname')) + ' ' + getObjectKey(item.responsible, 'lastname').toUpperCase(),
                'email': getObjectKey(item.responsible, 'email'),
                'l_p_client': getObjectKey(item.responsible, 'l_p_client'),
            }
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildCampaigns(data){
    let result = []

    for(const item of data){
        result.push(buildCampaign(item))
    }

    return result
}

function buildCampaign(item, returnArray = false){
    let result = null

    if(isDefined(item)){
        result = {
            "id": getObjectKey(item, 'id'),
            "name": getObjectKey(item, 'name').toUpperCase(),
            "owner": getObjectKey(item, 'owner'),
            "type": getObjectKey(item, 'type'),
            "created": dateFormatted(getObjectKey(item, 'created')),
            "sent": dateFormatted(getObjectKey(item, 'sent')),
            "finished": dateFormatted(getObjectKey(item, 'finished')),
            "visual": getObjectKey(item, 'visual'),
            "document": getObjectKey(item, 'document'),
        }

        if(isDefined(item.Contacts)){
            result['Contacts'] = []
            for(const contact of item.Contacts) {
                result['Contacts'].push(
                    {
                        'id': getObjectKey(contact, 'id'),
                        'firstname': capitalize(getObjectKey(contact, 'name').split(' ')[0]),
                        'lastname': getObjectKey(contact, 'name').split(' ')[1].toUpperCase(),
                        'name': capitalize(getObjectKey(contact, 'name').split(' ')[0]) + ' ' + getObjectKey(contact, 'name').split(' ')[1].toUpperCase(),
                        'function': getObjectKey(contact, 'function'),
                        'company': getObjectKey(contact, 'company')?getObjectKey(contact, 'company').toUpperCase():'-',
                        'responsible': getObjectKey(contact, 'responsible') ? getObjectKey(contact, 'responsible').toUpperCase() : '-',
                        'responsible_email': getObjectKey(contact, 'responsible_email') ? getObjectKey(contact, 'responsible_email') : '-',
                        'return': getObjectKey(contact, 'return'),
                    }
                )
            }
        }

        if(isDefined(item.disciplines)) {
            result['disciplines'] = item.disciplines
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildDisciplines(data){
    let result = []

    for(const item of data){
        result.push(buildDiscipline(item))
    }

    return result
}

function buildDiscipline(item, returnArray = false){
    let result = null

    if(isDefined(item)){
        result = {
            "id": getObjectKey(item, 'id'),
            "type": getObjectKey(item, 'type', true),
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildInterests(data){
    let result = []

    for(const item of data){
        result.push(buildInterest(item))
    }

    return result
}

function buildInterest(item, returnArray = false){
    let result = null

    if(isDefined(item)){
        result = {
            "id": getObjectKey(item, 'id'),
            "label": getObjectKey(item, 'label', true),
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildEvents(data){
    let result = []

    for(const item of data){
        result.push(buildEvent(item))
    }

    return result
}

function buildEvent(item, returnArray = false){
    let result = null

    if(isDefined(item)){
        result = {
            "id": parseInt(getObjectKey(item, 'id')),
            "title": getObjectKey(item, 'title', true),
            "description": getObjectKey(item, 'description', true),
            "location": getObjectKey(item, 'location', true),
            "link": getObjectKey(item, 'link', true),
            "type": getObjectKey(item, 'type', true),
            "start_date": getObjectKey(item, 'start_date', true),
            "end_date": getObjectKey(item, 'end_date', true),
            "color": "appointment", //it can be "vacation"
        }

        if(isDefined(item.attendees)){
            result['attendees'] = []
            for(const obj of getObjectKey(item, 'attendees')){
                result['attendees'].push({
                    'id': parseInt(getObjectKey(obj, 'id')),
                    'user': {
                        'email': getObjectKey(obj.user, 'email', true)
                    }
                })
            }
        }

        /*if(isDefined(item.reminders)){
            for(const obj of getObjectKey(item, 'reminders')){
                result['attendees'].push({
                    'id': parseInt(getObjectKey(obj, 'id')),
                    'user': {
                        'email': getObjectKey(obj.user, 'email', true)
                    }
                })
            }
        }*/

        if(returnArray){
            result = [result]
        }
    }

    return result
}
