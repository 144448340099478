import {
    UNSELECT_FOLDER,
    SET_CURRENT_FOLDER,
    RESET_CURRENT_FOLDER,
    ADD_FOLDER,
    EDIT_FOLDER,
    DELETE_FOLDER,
    TOGGLE_DIRECTORY_LOADED,

    ADD_KEYWORDS,
    ADD_FILES,
    DELETE_FILE,
    SHARE_FILE,
    UNSHARE_FILE,

    SET_SEARCHING,
    RESET_SEARCHING,
    ADD_FILES_SEARCH,
    ADD_FOLDERS_SEARCH,
    ADD_KEYWORDS_SEARCH,
    ADD_KEYWORDS_FILES_SEARCH,
    RESET_KEYWORDS_FILES_SEARCH,

    LOAD_TIMELINE,

    ADD_FAV,
    REMOVE_FAV,
    SET_SEARCHING_FOLDER,
    RESET_SEARCHING_FOLDER,
    LOAD_FILE_STATUS,
    UPDATE_FILE,
    SELECT_FILE,
    UNSELECT_FILE,
    LOAD_DOCUMENT_REQUEST_HISTORY,
    ADD_ITEM_DOCUMENT_REQUEST_HISTORY,
    ADD_ITEM_TIMELINE,
    TOGGLE_SIDEBAR,
    SWITCH_NOTIFICATION_SENT_STATE,
    ADD_TODO
} from "../mutations-types";
import axiosRequest from "../../AuthenticatedRoute";
import {
    COMPANY,
    FOLDER_PATH_SEPARATOR,
    MERCURE_CREATE_EVENT,
    MERCURE_DELETE_EVENT,
    MERCURE_FILES_TOPIC, MERCURE_FOLDERS_TOPIC,
    MERCURE_UPDATE_EVENT,
    MERCURE_SHARING_TOPIC,
    PERSONAL,
    MERCURE_TODO_TASK_TOPIC
} from "@/constant";
import {
    downloadURI,
    getObjectKey,
    buildFormData,
    isDefined,
    convertDateFormat,
    stringIsNotBlank,
    getFullName,
    isEmptyObject,
    dateFormatted,
    isArray,
    capitalize
} from "@/utils";
import { buildTodo } from "./todos";

const namespaced = true

const rootSlug = 'root';

const state = {
    currentFolder: {},
    currentSwitchState:0,
    selectedFile: {},
    sharingNotification: null,
    folders: [],
    directoryLoaded: false,
    files: [],
    fileStatus: [],
    fileStatusLoaded: false,
    timeline: [],
    timelineLoaded: false,
    searching: false,


    /**
     * Arrays where we store folder, files and keywords after a search query
     */
    showTagFiles: false,
    selectedTagSearch: '',
    searchingFiles: [],

    searchingFolder: false,
    searchingFoldersPersonnal: [],
    searchingFoldersCompany: [],
    directorySearchContainer: 0,

    searchingFoldersPersonnalGlobal: [],
    searchingFoldersCompanyGlobal: [],

    searchingKeywords: [],
    keywordFiles: [],

    keywords: [],
    fileKeywordLoaded: false,

    documentRequestHistory: [],

    isSidebarOpen: false
}

const mutations = {
    //Folder

    [UNSELECT_FOLDER](state, slug) {
        //closing the folder
        state.folders.filter(folder => folder.slug === slug).map(folder => folder.opened = false)
    },

    [SWITCH_NOTIFICATION_SENT_STATE](state, notification) {
        state.sharingNotification = notification
    },

    [SET_CURRENT_FOLDER](state, {folder}){
        //selecting the folder
        state.currentFolder = folder
        //opening the folder
        state.folders.filter(item => item.slug === folder.slug).map(item_in => item_in.opened = true)
    },

    [RESET_CURRENT_FOLDER](state) {
        state.currentFolder = {name: '', slug: '', type: 0, depth: 0}
    },

    [ADD_FOLDER](state, {folders}) {
        for(const folder of folders) {
            if(state.folders.filter(item => item.slug === folder.slug).length === 0) {
                state.folders.push(folder)
            }else{
                state.folders = state.folders.map(item => item.slug === folder.slug ? folder:item)
            }
        }
    },

    [EDIT_FOLDER](state, {slug, newName, newArianne}) {
        state.folders.filter(folder => folder.slug === slug).map(folder => {
            folder.name = newName
            folder.arianne = newArianne.split(FOLDER_PATH_SEPARATOR)
        })
        state.currentFolder.name = newName
        state.currentFolder.arianne = newArianne.split(FOLDER_PATH_SEPARATOR)
    },

    [DELETE_FOLDER](state, {slug})  {
        state.folders = state.folders.filter(dir => dir.slug !== slug)
    },

    [TOGGLE_DIRECTORY_LOADED] (state) {
        state.directoryLoaded = !state.directoryLoaded
    },

    //File
    [LOAD_FILE_STATUS](state, {status}) {
        state.fileStatus = []
        for(const item of status) {
            state.fileStatus.push(item)
        }

        state.fileStatusLoaded = true
    },

    [ADD_KEYWORDS](state, {keywords}) {
        for(const keyword of keywords) {
            if(state.keywords.filter(item => item.name === keyword.name).length === 0) {
                state.keywords.push(keyword)
            }
        }
        state.fileKeywordLoaded = true
    },

    [SELECT_FILE](state, {file}) {
        state.selectedFile = file
    },

    [UNSELECT_FILE](state) {
        state.selectedFile = {slug: ''}
    },

    [ADD_FILES](state, {files}) {
        for(const file of files) {
            if(file !== null && state.files.filter(item => item.slug === file.slug).length === 0) {
                state.files.push(file)
            }else{
                //here we should update file
                state.files = state.files.map(item => item.slug === file.slug ? file:item)
            }
        }
    },
    setSwitchState(state,currentSwitch){
        state.currentSwitchState=currentSwitch
    },
    [UPDATE_FILE](state, {file}) {
        state.files = state.files.map(item => item.slug === file.slug ? file:item)
    },

    [DELETE_FILE](state, {slug}) {
        state.files = state.files.filter(item => item.slug !== slug)
    },

    [SHARE_FILE](state, {slugFile, slugFolder}) {
        state.files.filter(file => file.slug === slugFile).map(file => file.symbolikParents = [...file.symbolikParents, slugFolder])
    },

    [UNSHARE_FILE](state, {slugFile, slugFolder}) {
        state.files.filter(file => file.slug === slugFile).map(file => file.symbolikParents = file.symbolikParents.filter(item => item !== slugFolder))
    },

    //Search
    [RESET_SEARCHING_FOLDER](state,type) {
        state.searchingFolder = false;
        state.directorySearchContainer = 0
        if(type===PERSONAL){
            state.searchingFoldersPersonnal=[]
        }
        else if(type===COMPANY){
            state.searchingFoldersCompany=[]
        }
    },

    resetSearchingFolderGlobal(state,type) {
        if(type===PERSONAL){
            state.searchingFoldersPersonnalGlobal=[]
        }
        else if(type===COMPANY){
            state.searchingFoldersPersonnalGlobal=[]
        }
    },

    [SET_SEARCHING_FOLDER](state, {type}) {
        state.searchingFolder = true;
        state.directorySearchContainer = type
    },

    [SET_SEARCHING](state) {
        state.searching = true;
    },

    [RESET_SEARCHING](state,folderCase) {
        state.searching = false
        //state.searchingFolder = false

        state.searchingFiles = []
        ///state.searchingFolders = []
        state.searchingKeywords = []
        if(folderCase){
            state.searchingFolder = false 
            if(state.currentFolder.type===PERSONAL){
                state.searchingFoldersPersonnal = []
            }
            else if(state.currentFolder.type===COMPANY){
                state.searchingFoldersCompany = []
            }
            // else{
            //     state.searchingFoldersCompany = []
            //     state.searchingFoldersPersonnal = []
            // }
        }
    },

    [ADD_FOLDERS_SEARCH](state, {folders,type}) {
        if(type===PERSONAL){
            state.searchingFoldersPersonnal=[]
            for(const folder of folders) {
                if(state.searchingFoldersPersonnal.filter(item => item.slug === folder.slug).length === 0) {
                    state.searchingFoldersPersonnal.push(folder)
                }
            }
        }
        else if(type===COMPANY){
            state.searchingFoldersCompany=[]
            for(const folder of folders) {
                if(state.searchingFoldersCompany.filter(item => item.slug === folder.slug).length === 0) {
                    state.searchingFoldersCompany.push(folder)
                }
            }
        }
    },

    addGlobalFolders(state, {folders,type}) {
        if(type===PERSONAL){
            state.searchingFoldersPersonnalGlobal=[]
            for(const folder of folders) {
                if(state.searchingFoldersPersonnalGlobal.filter(item => item.slug === folder.slug).length === 0) {
                    state.searchingFoldersPersonnalGlobal.push(folder)
                }
            }
        }
        else if(type===COMPANY){
            state.searchingFoldersCompanyGlobal=[]
            for(const folder of folders) {
                if(state.searchingFoldersCompanyGlobal.filter(item => item.slug === folder.slug).length === 0) {
                    state.searchingFoldersCompanyGlobal.push(folder)
                }
            }
        }
    },

    [ADD_FILES_SEARCH](state, {files}) {
        state.searchingFiles = []

        for(const file of files) {
            if(state.searchingFiles.filter(item => item.slug === file.slug).length === 0) {
                state.searchingFiles.push(file)
            }
        }
    },

    [ADD_KEYWORDS_SEARCH](state, {keywords}) {
        state.searchingKeywords = []

        for(const keyword of keywords) {
            if(state.searchingKeywords.filter(item => item.id === keyword.id).length === 0) {
                state.searchingKeywords.push(keyword)
            }
        }
    },

    [ADD_KEYWORDS_FILES_SEARCH](state, {files, name}) {
        state.keywordFiles = []
        for(const file of files) {
            if(state.keywordFiles.filter(item => item.slug === file.slug).length === 0) {
                state.keywordFiles.push(file)
            }
        }
        state.showTagFiles = true
        state.selectedTagSearch = name
    },

    [RESET_KEYWORDS_FILES_SEARCH](state) {
        state.keywordFiles = []
        state.showTagFiles = false
    },

    //Timeline
    [LOAD_TIMELINE](state, data) {
        for(const item of data) {
            state.timeline.push(item)
        }

        state.timelineLoaded = true
    },

    [ADD_ITEM_TIMELINE](state, {item}){
        state.timeline.push(item)
    },

    [LOAD_DOCUMENT_REQUEST_HISTORY](state, data){
        for(const item of data){
            state.documentRequestHistory.push(item)
        }
    },

    [ADD_ITEM_DOCUMENT_REQUEST_HISTORY](state, {item}){
        state.documentRequestHistory.push(item)
    },

    //Favorites
    [ADD_FAV](state, {slug}) {
        state.folders.filter(folder => folder.slug === slug).map(folder => folder.fav = true)
    },

    [REMOVE_FAV](state, {slug}) {
        state.folders.filter(folder => folder.slug === slug).map(folder => folder.fav = false)
    },

    [TOGGLE_SIDEBAR](state) {
        state.isSidebarOpen = !state.isSidebarOpen
    }
}

const actions = {
    resetSearchingState({commit},folderCase=false){
        if(folderCase){
            commit(RESET_SEARCHING,folderCase) 
        }
        else{
            commit(RESET_SEARCHING)  
        }
    },
    // Directory
    toggleDirectoryLoaded({commit}) {
        commit(TOGGLE_DIRECTORY_LOADED)
    },

    async loadDirs({commit, dispatch}, {type}){
        dispatch('listenFileSharing')
        dispatch('listenFileUpdates') //Listening to update coming from Mercure server
        dispatch('listenFolderUpdates') //Listening to update coming from Mercure server
        dispatch('listenTodoTaskUpdates')
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/ged/folder/list/'+type,
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    const items = getObjectKey(data, 'folders')

                    if(items !== null){
                        const folders = buildFolders(items)
                        commit(ADD_FOLDER, {folders})

                        for(const folder of folders) {
                            dispatch('listFolderFiles', {slug: folder.slug})
                            dispatch('loadSubFolders',{slug: folder.slug, type: folder.type, selectFolder: false} )
                        }
                    }
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line
    async loadGEDDirsOnly({}, {type}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/ged/folder/list/'+type,
            }).then(response => {
                const data = response.data.data
                if(isDefined(data)) {
                    const items = getObjectKey(data, 'folders')
                    if(items !== null){
                        resolve(buildFolders(items))
                    }else{
                        resolve([])
                    }
                }
            }).catch(error => {
                reject(error)
            })
        })
    },


    // Folder
    createFolder({commit, state}, {name, type}) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            if(stringIsNotBlank(name)) {
                formData.append('name', name)
            }

            let folderType
            if(
                !isEmptyObject(state.currentFolder) && // If there is a selected folder
                parseInt(state.currentFolder.type) === parseInt(type) // If they are from the same view
            ) {
                formData.append('parent', state.currentFolder.slug)
                folderType = state.currentFolder.type
            }else{
                folderType = type
            }
            formData.append('type', folderType)

            axiosRequest({
                url: '/ged/folder/',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    commit(ADD_FOLDER, {folders: buildFolders([data])})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    creatingFolderFromUpload({commit},data){
        commit(ADD_FOLDER, {folders: buildFolders([data])})
    },
    creatingFileFromUpload({commit},data){
        commit(ADD_FILES, {files: buildFiles([data])})
    },


    // createFolder2({commit, state}, {name, type,parent}) {
    //     return new Promise((resolve, reject) => {

    //         let formData = new FormData()

    //         if(stringIsNotBlank(name)) {
    //             formData.append('name', name)
    //         }

    //         let folderType
    //         if(parent){
    //             formData.append('parent', parent)  
    //         }
    //         folderType = state.currentFolder.type
        
    //         folderType = type
            
    //         formData.append('type', folderType)

    //         axiosRequest({
    //             url: '/ged/folder/',
    //             data: formData,
    //             headers: { "Content-Type": "multipart/form-data" },
    //         }).then(response => {
    //             const data = response.data.data

    //             if(isDefined(data)) {
    //                 commit(ADD_FOLDER, {folders: buildFolders([data])})
    //             }

    //             resolve(response)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    async deleteFolder({commit}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/ged/folder/'+data.slug,
            }).then(response => {
                if(response){
                    commit(DELETE_FOLDER, {slug: data.slug})
                    commit(RESET_CURRENT_FOLDER)
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async editFolder({commit}, {slug, name}) {
        return new Promise((resolve, reject) => {
            const   method = "PUT"

            axiosRequest({
                method,
                url: `/ged/folder/${slug}`,
                data: buildFormData({name}, method, true)
            }).then(response => {
                const result = getObjectKey(response.data, 'data')

                if(isDefined(result)) {
                    commit(EDIT_FOLDER, {
                        slug: getObjectKey(result, 'slug', true),
                        newName: getObjectKey(result, 'name', true),
                        newArianne: getObjectKey(result, 'arianne', true)
                    })
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    listFolderFiles({commit}, {slug}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/ged/folder/listFiles/'+slug,
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    commit(ADD_FILES, {files: buildFiles(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    loadSubFolders({commit, state, dispatch}, {slug, type, selectFolder = true}){
        const items = state.folders.filter(item => item.slug === slug)

        if(items.length !== 1) {
            return null
        }

        let folder = items[0]

        return new Promise((resolve, reject) => {
            let formData = new FormData()
            formData.append('slug', slug);

            axiosRequest({
                url: '/ged/folder/list/'+type,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {

                    dispatch('listFolderFiles', {slug: folder.slug})
                    if(selectFolder) {

                        const item = data.item
                        if (getObjectKey(data, 'item')) {
                            commit(ADD_FOLDER, {folders: buildFolders([item], true)})
                        }

                        commit(ADD_FILES_SEARCH, {files: []})

                        const fldrs = getObjectKey(data, 'folders')
                        if (fldrs) {
                            commit(ADD_FOLDER, {folders: buildFolders(fldrs)})
                        }

                        const ancestor = getObjectKey(data, 'ancestors')
                        if (ancestor !== null) {
                            commit(ADD_FOLDER, {folders: buildFolders(data.ancestors, true)})
                        }
                        
                        //????
                        //commit(RESET_KEYWORDS_FILES_SEARCH)
                        // commit(RESET_SEARCHING_FOLDER)
                        commit(RESET_SEARCHING)
                    }
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line
    loadGEDSubFoldersOnly({}, {slug, type}){
        return new Promise((resolve, reject) => {

            let formData = new FormData()
            formData.append('slug', slug);

            axiosRequest({
                url: '/ged/folder/list/'+type,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    const folders = getObjectKey(data, 'folders')
                    const ancestor = getObjectKey(data, 'ancestors')
                    if(folders !== null && ancestor !== null){
                        resolve({
                            folders: buildFolders(folders) ?? [],
                            ancestors: buildFolders(ancestor) ?? []
                        })
                    }else{
                        resolve([])
                    }
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    async selectFolder({commit, dispatch, state}, {slug}) {

        let folder = state.folders.filter(folder => folder.slug === slug)[0]

        if ( undefined === folder ){
            let mergedSearchingFolders=[...state.searchingFoldersPersonnal,...state.searchingFoldersCompany]
            folder = mergedSearchingFolders.filter(folder => folder.slug === slug)[0]
            //**?? */
            commit(RESET_SEARCHING,true)
        }

        if ( undefined !== folder ) {
            commit(SET_CURRENT_FOLDER, {folder})

            dispatch('loadSubFolders',{slug: folder.slug, type: folder.type} )

            //If subfolders are not yet loaded, we load them
            // if( state.folders.filter(folder => folder.parent === slug).length === 0 ) {
                // dispatch('loadSubFolders',{slug: folder.slug, type: folder.type} )
            // }
        }

    },

    async unselectFolder({commit, state}, {slug}) {
        await commit(UNSELECT_FOLDER, slug)

        if(state.currentFolder.slug === slug) { // we should refresh the content vue
            commit(RESET_CURRENT_FOLDER)
        }
    },

    // File
    async loadFileStatus({commit}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/ged/fileStatus/',
            }).then(response => {
                const data = response.data.data
                if(isDefined(data)){
                    commit(LOAD_FILE_STATUS, {status: data})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadKeywords({commit}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/ged/keyword/list',
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    commit(ADD_KEYWORDS, {keywords: buildKeywords(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // File
    async loadKeywordFile({commit}, {id, name}) {
        return new Promise((resolve, reject) => {

            axiosRequest({
                method: 'GET',
                url: '/ged/file/listTagFiles/'+id,
            }).then(response => {
                const data = response.data.data
                if(isDefined(data)){
                    commit(ADD_KEYWORDS_FILES_SEARCH, {files: buildFiles(data), name})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async selectFile({commit, state, dispatch}, {slug, selectFolder = false}) {
        const item = state.files.filter(item => item.slug === slug)
        if(item.length === 1){
            const file = item[0]

            if(selectFolder){
                dispatch('selectFolder', {slug: file.folder})
            }
            commit(SELECT_FILE, {file})
        }else{
            await dispatch('getFile', {slug})

            const file = state.files.find(item => item.slug === slug)

            dispatch('getFolder', {slug: getObjectKey(file, 'folder', true), selectFolder: true})
        }
    },

    async getFile({commit}, {slug}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/ged/file/view/'+slug,
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    commit(ADD_FILES, {files: buildFiles([data])})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async getFolder({commit, dispatch}, {slug, selectFolder = false}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/ged/folder/view/'+slug
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    const folders = buildFolders([data])
                    commit(ADD_FOLDER, {folders})

                    if(selectFolder){
                        dispatch('selectFolder', {slug})
                    }
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

    },

    unselectFile({commit}) {
        commit(UNSELECT_FILE)
    },

    createFile({commit, state,dispatch}, data) {
        //upload file +1
        if(isEmptyObject(state.currentFolder))
            return null

        return new Promise((resolve, reject) => {
            dispatch('uploadSys/addNumFilesToTotal',1,{root:true})
            let formData = new FormData()
            formData.append('name', data.name);
            formData.append('title', data.title);
            formData.append('reference', data.file);

            if(data.description.trim() !== '') {
                formData.append('description', data.description);
            }

            if(data.keywords !== undefined) {
                formData.append('keywords', data.keywords.join(','))
            }


            axiosRequest({
                method: 'POST',
                url: '/ged/file/create/'+state.currentFolder.slug,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    commit(ADD_FILES, {files: buildFiles([data])})
                }
                resolve(response)
                dispatch('uploadSys/addNumFilesToTotal',-1,{root:true})
            }).catch(error => {
                reject(error)
                dispatch('uploadSys/addNumFilesToTotal',-1,{root:true})
            })
        })
    },

    // createFile2({commit}, data) {
    //     // if(isEmptyObject(state.currentFolder))
    //     //     return null

    //     return new Promise((resolve, reject) => {

    //         let formData = new FormData()
    //         formData.append('name', data.name);
    //         formData.append('title', data.title);
    //         formData.append('reference', data.file);

    //         if(data.description.trim() !== '') {
    //             formData.append('description', data.description);
    //         }

    //         if(data.keywords !== undefined) {
    //             formData.append('keywords', data.keywords.join(','))
    //         }


    //         axiosRequest({
    //             method: 'POST',
    //             url: '/ged/file/create/'+data.currentFolder,
    //             data: formData,
    //             headers: { "Content-Type": "multipart/form-data" },
    //         }).then(response => {
    //             const data = response.data.data

    //             if(isDefined(data)){
    //                 commit(ADD_FILES, {files: buildFiles([data])})
    //             }

    //             resolve(response)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // eslint-disable-next-line
    createEmptyFile({commit, state}, data) {
        if(isEmptyObject(state.currentFolder))
            return null

        return new Promise((resolve, reject) => {

            let formData = new FormData()
            formData.append('name', data.title);
            formData.append('title', data.title);
            formData.append('doctype', data.doctype);

            if(data.description.trim() !== '') {
                formData.append('description', data.description);
            }

            if(data.keywords !== undefined) {
                formData.append('keywords', data.keywords.join(','))
            }

            axiosRequest({
                method: 'POST',
                url: '/ged/file/createEmptyFile/'+state.currentFolder.slug,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    commit(ADD_FILES, {files: buildFiles([data])})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line no-empty-pattern
    downloadFile({}, {slug, title, type,conversationSlug}) {
        return new Promise((resolve, reject) => {
            const fileName = title + '.'+type
            axiosRequest({
                method: 'POST',
                url: conversationSlug?'/ged/file/download/'+slug+'?conversationSlug='+conversationSlug:'/ged/file/download/'+slug,
                responseType: 'blob'
            }).then(response => {

                if(response.status === 200) {
                    downloadURI(response.data, fileName)
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line
    getFileKeys({}, {slug}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/ged/file/fileKeys/'+slug,
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line
    getDocumentHistory({}, {slug}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/ged/file/listDocumentHistory/'+slug,
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line
    getDocumentUrl({}, {slug, filename}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: `/ged/file/DocumentUrl/${slug}/${filename}`,
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    

    deleteFile({commit}, {slug}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/ged/file/'+slug,
            }).then(response => {
                commit(DELETE_FILE, {slug})

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    searchFile({commit, state}, {searchQuery}) {
        commit(RESET_SEARCHING,true)
        let formData = new FormData()
        formData.append('query', searchQuery);

        if(state.currentFolder.slug) {
            formData.append('type', state.currentFolder.type)
            formData.append('slug', state.currentFolder.slug)
        }

        commit(SET_SEARCHING)

        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/ged/file/search',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    commit(ADD_FILES_SEARCH, {files: buildFiles(data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line no-unused-vars
    copyFile({_},{fileSlug,projectSlug}){
        const data=new FormData()
        data.append('fileSlug',fileSlug)
        data.append('projectCodeId',projectSlug)
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'POST',
                url:'/ged/file/copyFile',
                data:data,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response=>{
                resolve(response)
            }).catch(error=>{
                reject(error)
            })
        })
    },

    // eslint-disable-next-line
    saveFile({}, {fileUrl, targetFolderSlug, projectSlug}) {
        const data=new FormData()
        data.append('fileUrl',fileUrl)
        data.append('targetFolderSlug', targetFolderSlug)
        data.append('projectSlug',projectSlug)
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'POST',
                url:'/ged/file/saveFile',
                data:data,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response=>{
                resolve(response)
            }).catch(error=>{
                reject(error)
            })
        })
    },

    resetSearchingFolder({commit},type){
        commit(RESET_SEARCHING_FOLDER,type)
    },
    searchFolders({commit, state}, {searchQuery, isFromDirectory,type,global=false}) {
        if(global){
            commit('resetSearchingFolderGlobal',type)
        }else{
            commit(RESET_SEARCHING_FOLDER,type)  
        }
        return new Promise((resolve, reject) => {
            let formData = new FormData()

            formData.append('query', searchQuery);
            

    
            if(state.currentFolder.slug && state.currentFolder.type===type){
                formData.append('slug', state.currentFolder.slug)
                formData.append('type',state.currentFolder.type)
                if(isFromDirectory){
                    var typeFolder=state.currentFolder.type
                    commit(SET_SEARCHING_FOLDER, {typeFolder})
                }
    
            }
            if(!state.currentFolder.slug && type!==0){
                commit('setSwitchState',type)
                formData.append('type',type)
            }

       
            axiosRequest({
                method: 'POST',
                url: '/ged/folder/search',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                commit(SET_SEARCHING)
                const data = response.data.data

                if(isDefined(data) && !global) {
                    commit(ADD_FOLDERS_SEARCH, {type:type,folders: buildFolders(response.data.data)})
                }
                else if(isDefined(data) && global){
                    commit('addGlobalFolders', {type:type,folders: buildFolders(response.data.data)})
                }
                else{
                    commit(RESET_SEARCHING_FOLDER)
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    searchKeywords({commit, state}, {searchQuery, type}) {
        return new Promise((resolve, reject) => {
            let formData = new FormData()
            formData.append('query', searchQuery);

            if(isEmptyObject(state.currentFolder)) {
                formData.append('type', type)
            }else{
                formData.append('slug', state.currentFolder.slug)
            }

            axiosRequest({
                method: 'POST',
                url: '/ged/file/searchKeywords',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                commit(SET_SEARCHING)

                const data = response.data.data
                if(isDefined(data)) {
                    commit(ADD_KEYWORDS_SEARCH, {keywords: data})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    stopSearch({commit}){
        commit(RESET_SEARCHING)
    },

    editFile({commit}, data){
        return new Promise((resolve, reject) => {
            const method = "PUT"

            axiosRequest({
                method: method,
                url: '/ged/file/'+data.slug,
                data: buildFormData(data, method, true),
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    const file = buildSingleFile(data)
                    commit(UPDATE_FILE, {file})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    shareFile({commit, rootState, state}, {slugFile, slugFolder}) {
        if(isEmptyObject(state.currentFolder)) {
            return null
        }

        return new Promise((resolve, reject) => {

            let formData = new FormData()
            formData.append('slugFile', slugFile);
            formData.append('slugFolder', slugFolder);

            axiosRequest({
                method: 'POST',
                url: '/ged/file/share',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer '+rootState.account.userInfos.token
                }
            }).then(response => {
                commit(SHARE_FILE, {slugFile, slugFolder})

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    unShareFile({commit, state}, {slugFile, slugFolder}) {
        if(isEmptyObject(state.currentFolder)) {
            return null
        }

        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append('slugFile', slugFile);
            formData.append('slugFolder', slugFolder);

            axiosRequest({
                method: 'POST',
                url: '/ged/file/unShare',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                commit(UNSHARE_FILE, {slugFile, slugFolder})

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async addFav({commit}, {slug}) {
        return new Promise((resolve, reject) => {

            axiosRequest({
                method: 'POST',
                url: '/ged/folder/addFav/'+slug
            }).then(response => {
                const folderSlug = response.data.data
                commit(ADD_FAV, {slug:folderSlug});
                resolve(response)
            }).catch(error => {
                reject(error)
            })

        })
    },

    async removeFav({commit}, {slug}) {
        return new Promise((resolve, reject) => {

            axiosRequest({
                method: 'DELETE',
                url: '/ged/folder/removeFav/'+slug
            }).then(response => {
                commit(REMOVE_FAV, {slug});

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    loadTimeline({commit, dispatch}) {
        return new Promise((resolve, reject) => {

            axiosRequest({
                method: 'GET',
                url: '/ged/getHistory',
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    const timelineData = buildTimelines(data)
                    commit(LOAD_TIMELINE, timelineData);
                }

                dispatch('loadDocumentRequestHistory')

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    loadDocumentRequestHistory({commit, rootState}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/ged/file/history/requestRights',
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)){
                    commit(LOAD_DOCUMENT_REQUEST_HISTORY, buildDocReqHists(data, rootState.account.listUsers));
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async assignTask({commit}, data) {
        return new Promise((resolve, reject) => {
            const method = "POST"
            axiosRequest({
                method,
                url: '/ged/file/addTask/'+data.slug,
                data: buildFormData(data, method),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(async (response) =>  {
                const data = response.data[0]

                if (isDefined(data)) {
                    commit('todos/' + ADD_TODO, {todos: buildTodo(data, true)}, {root: true});
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line no-empty-pattern
    shareFolder({}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/ged/folder/share/'+data.slug,
                data: buildFormData(data),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line no-empty-pattern
    requestRightsFolder({}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/ged/file/requestOpenRights/'+getObjectKey(data, 'slug'),
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    sendNotification({commit}, slug) {
        commit(SWITCH_NOTIFICATION_SENT_STATE, slug)
    },
    
    listenFileSharing({rootState, commit, dispatch}){
        const userEmail = rootState.account.userInfos.email

        if(stringIsNotBlank(userEmail)) {
            const
                eventURL = process.env.VUE_APP_MERCURE_SERVER_URL + MERCURE_SHARING_TOPIC + '/' + userEmail,

                eventSource = new EventSource(eventURL, {
                    headers: {
                        'Authorization': 'Bearer ' + rootState.account.userInfos.mercureToken
                    }
                })

            eventSource.onmessage = async event => {
                // Will be called every time an update is published by the server
                const
                    data = JSON.parse(event.data),
                    mercureEvent = getObjectKey(data, 'event', true),
                    slug = getObjectKey(data, 'data', true)

                switch (mercureEvent){
                    case MERCURE_CREATE_EVENT:
                        await dispatch('sendNotification', slug)
                        break
                    case MERCURE_DELETE_EVENT:
                        commit(DELETE_FILE, {slug})
                        break
                    default:
                        console.error('this event is not yet managed')
                }

            }

            eventSource.onerror = err => {
                console.error('File ... Something went wrong ', err);
            }
        }

    },
    
    listenTodoTaskUpdates({rootState, commit}){
        const userEmail = rootState.account.userInfos.email

        if(stringIsNotBlank(userEmail)) {
            const
                eventURL = process.env.VUE_APP_MERCURE_SERVER_URL + MERCURE_TODO_TASK_TOPIC + '/' + userEmail,

                eventSource = new EventSource(eventURL, {
                    headers: {
                        'Authorization': 'Bearer ' + rootState.account.userInfos.mercureToken
                    }
                })

            eventSource.onmessage = async event => {
                // Will be called every time an update is published by the server
                const
                    data = JSON.parse(event.data),
                    mercureEvent = getObjectKey(data, 'event', true),
                    slug = getObjectKey(data, 'data', true)

                switch (mercureEvent){
                    case MERCURE_CREATE_EVENT:
                        commit('todos/' + ADD_TODO, {todos: buildTodo(slug, true)}, {root: true});
                        break
                    default:
                        console.error('this event is not yet managed')
                }

            }

            eventSource.onerror = err => {
                console.error('File ... Something went wrong ', err);
            }
        }

    },
    
    listenFileUpdates({rootState, commit, dispatch}){
        const userEmail = rootState.account.userInfos.email

        if(stringIsNotBlank(userEmail)) {
            const
                eventURL = process.env.VUE_APP_MERCURE_SERVER_URL + MERCURE_FILES_TOPIC + '/' + userEmail,

                eventSource = new EventSource(eventURL, {
                    headers: {
                        'Authorization': 'Bearer ' + rootState.account.userInfos.mercureToken
                    }
                })

            eventSource.onmessage = async event => {
                // Will be called every time an update is published by the server
                const
                    data = JSON.parse(event.data),
                    mercureEvent = getObjectKey(data, 'event', true),
                    slug = getObjectKey(data, 'data', true)

                switch (mercureEvent){
                    case MERCURE_CREATE_EVENT:
                    case MERCURE_UPDATE_EVENT:
                        await dispatch('getFile', {slug})
                        break
                    case MERCURE_DELETE_EVENT:
                        commit(DELETE_FILE, {slug})
                        break
                    default:
                        console.error('this event is not yet managed')
                }
                dispatch('loadTimeline')

            }

            eventSource.onerror = err => {
                console.error('File ... Something went wrong ', err);
            }
        }

    },

    listenFolderUpdates({rootState, commit}){
        const userEmail = rootState.account.userInfos.email

        if(stringIsNotBlank(userEmail)) {
            const eventURL = process.env.VUE_APP_MERCURE_SERVER_URL + MERCURE_FOLDERS_TOPIC + '/' + userEmail,

                eventSource = new EventSource(eventURL, {
                    headers: {
                        'Authorization': 'Bearer ' + rootState.account.userInfos.mercureToken
                    }
                })

            eventSource.onmessage = event => {
                /**
                 * This method is called when we received an mercure's update on this chanel
                 */
                const
                    serverData = JSON.parse(event.data),
                    serverEvent = getObjectKey(serverData, 'event'),
                    // data = buildFolder(getObjectKey(serverData, 'data')),
                    data = getObjectKey(serverData, 'data'),
                    slug  = getObjectKey(data, 'slug'),
                    folders = [data]

                switch (serverEvent){
                    case 'delete':
                        commit(DELETE_FOLDER, {slug})
                        break
                    case 'add':
                    case 'share':
                        commit(ADD_FOLDER, {folders})
                        break
                    case 'edit':
                        commit(EDIT_FOLDER, {
                            slug: getObjectKey(data, 'slug', true),
                            newName: getObjectKey(data, 'name', true),
                            newArianne: getObjectKey(data, 'arianne', true)
                        })
                }

            }

            eventSource.onerror = err => {
                console.error('File ... Something went wrong ', err);
            }
        }
    },

    // eslint-disable-next-line no-empty-pattern
    shareFileToContact({}, {fileSlug, id}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: `ged/file/shareToContact/${fileSlug}/${id}`,
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line no-empty-pattern
    shareFileToColleague({}, {fileSlug, id}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: `ged/file/shareToColleague/${fileSlug}/${id}`,
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    toggleSidebar({commit}) {
        commit(TOGGLE_SIDEBAR);
    }

}

const getters = {
    personalFolders: state => {
        return state.folders.filter(folder => folder.type === PERSONAL && folder.parent === rootSlug)
    },

    personalFavFolders: state => {
        return state.folders.filter(folder => folder.type === PERSONAL && folder.fav === true)
    },

    companyFolders: state => {
        return state.folders.filter(folder => folder.type === COMPANY && folder.parent === rootSlug)
    },

    companyFavFolders: state => {
        return state.folders.filter(folder => folder.type === COMPANY && folder.fav === true)
    },
}


export function buildFolders(data, openFolders = false) {
    let folders = []

    for(let folder of data) {
        const tmp = buildFolder(folder, openFolders)
        if(tmp !== null){
            folders.push(tmp)
        }
    }

    return folders
}

export function buildFolder(folder, openFolders = false) {
    if(isDefined(folder)) {
        const arianne = getObjectKey(folder, 'arianne')

        return {
            depth: getObjectKey(folder, 'depth'),
            name: getObjectKey(folder, 'name'),
            slug: getObjectKey(folder, 'slug'),
            type: getObjectKey(folder, 'type'),
            fav: getObjectKey(folder, 'fav'),
            parent: getObjectKey(folder, 'parent'),
            opened: openFolders,
            arianne: arianne !== null ? arianne.split(FOLDER_PATH_SEPARATOR):[],
            isOwner: getObjectKey(folder, 'isOwner')
        }
    }else{
        return null
    }
}

export function buildFiles(data) {
    let files = []

    for (let file of data) {
        files.push(buildSingleFile(file))
    }

    return files
}

export function buildSingleFile(file) {
    if(isDefined(file)) {
        return {
            title: capitalize(getObjectKey(file, 'title', true)),
            name: capitalize(getObjectKey(file, 'name', true)),
            folder: getObjectKey(file, 'parentFolder', true),
            slug: getObjectKey(file, 'slug', true),
            date: convertDateFormat(getObjectKey(file, 'date')),
            responsable: getObjectKey(file, 'responsable', true),
            isOwner: getObjectKey(file, 'isOwner'),
            isShared: getObjectKey(file, 'isShared'),
            type: getObjectKey(file, 'type', true),
            serverURL: getObjectKey(file, 'serverURL', true),
            symbolikParents: getObjectKey(file, 'symbolikParents'),
            description: getObjectKey(file.meta, 'description', true),
            keywords: getObjectKey(file.meta, 'keywords'),
            status: sanitizeStatus(getObjectKey(file.meta, 'status')),
            documentKey: getObjectKey(file, 'documentKey', true),
        }
    }else{
        return null
    }
}

export function buildTimelines(data) {
    let timelines = []

    for(let item of data) {
        timelines.push(buildTimeline(item))
    }

    return timelines
}

export function buildTimeline(item, returnArray = false) {
    let result = null

    if(isDefined(item)){
        result = {
            "id": getObjectKey(item, 'id'),
            "name": capitalize(getObjectKey(item, 'name')),
            "event": getObjectKey(item, 'event'),
            "slug": getObjectKey(item, 'slug'),
            "type": getObjectKey(item, 'type'),
            "dateEvent": dateFormatted(getObjectKey(item, 'date')),
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function sanitizeStatus(status) {
    return status === null ? {name:'', id:0}:status
}

function buildKeywords(data) {
    let keywords = []

    if(isArray(data)) {
        for (let keyword of data) {
            keywords.push({
                name: keyword.name,
                // code: keyword.name.substring(0, 2) + Math.floor((Math.random() * 10000000))
            })
        }
    }

    return keywords
}

function buildDocReqHists(data, userList){
    let items = []

    if(isDefined(data)){
        for(const item of data){
            items.push( buildDocReqHist(item, userList))
        }
    }

    return items
}

function buildDocReqHist(data, userList){
    if(isDefined(data)){
        const
            file = getObjectKey(data, 'file'),
            folder = getObjectKey(file, 'folder'),
            owner = getObjectKey(data, 'owner'),
            requester = getObjectKey(data, 'requester'),
            status = getObjectKey(data, 'status', true),
            date = dateFormatted(getObjectKey(data, 'date'))

        return {
            file: capitalize(getObjectKey(file, 'name')),
            slug: getObjectKey(file, 'slug'),
            folder: getObjectKey(folder, 'name'),
            requester: getFullName(userList, getObjectKey(requester, 'email', true)),
            owner: getFullName(userList, getObjectKey(owner, 'email', true)),
            status,
            date
        }
    }else{
        return null
    }
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}