import {
    ADD_LAWYER,
    LOAD_LAWYER
} from "@/store/mutations-types";
import axiosRequest from "@/AuthenticatedRoute";
import { getObjectKey, isDefined } from "@/utils";

const namespaced = true;

const state = {
    lawyers: [],
    lawyer: null,
    lawyersLoaded: false
}

const mutations = {
    [ADD_LAWYER](state, {lawyers}){
        for(const itemlawyer of lawyers){
            if(state.lawyers.filter(item => item.id === itemlawyer.id).length === 0){
                state.lawyers.push(itemlawyer)
            }
        }

        state.lawyersLoaded = true
    },

    [LOAD_LAWYER](state, {lawyer}){
        state.lawyer = lawyer
    },
}

const actions = {
    //Lawyers
    async loadLawyers({commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/lawyer/list/external'
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_LAWYER, {lawyers: buildLawyers(data)})
                    resolve(buildLawyers(data))
                }else{
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadLawyer({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/lawyer/load/' + id
            }).then(response => {
                const data = response.data.data
                if (isDefined(data)) {
                    commit(LOAD_LAWYER, {lawyer: buildLawyer(data)})
                }
                resolve(response)   
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadInternalLawyers({commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/lawyer/list/internal'
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_LAWYER, {lawyers: buildLawyers(data)})
                    resolve(buildLawyers(data))
                }else{
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced,
    state,
    actions,
    mutations,
}

function buildLawyers(data){
    let result = []

    for(const item of data){
        result.push(buildLawyer(item))
    }

    return result
}

export function buildLawyer(item, returnArray = false){
    let result = null

    if(isDefined(item)){

        const obj = isDefined(item.contact) ? item.contact:item

        result = {
            'avatar': getObjectKey(obj, 'avatar'),
            'additional_address': getObjectKey(obj, 'additional_address'),
            'address': getObjectKey(obj, 'address'),
            'city': getObjectKey(obj, 'city'),
            'civility': getObjectKey(obj, 'civility'),
            'country': getObjectKey(obj, 'country'),
            'email': getObjectKey(obj, 'email'),
            'firstname': getObjectKey(obj, 'firstname'),
            'function': getObjectKey(obj, 'function'),
            'id': parseInt(getObjectKey(obj, 'id')),
            'is_client': getObjectKey(item, 'is_client'),
            'is_lawyer': getObjectKey(item, 'is_lawyer'),
            'is_partner': getObjectKey(item, 'is_partner'),
            'is_prospect': getObjectKey(item, 'is_prospect'),
            'is_supplier': getObjectKey(item, 'is_supplier'),
            'lastname': getObjectKey(obj, 'lastname'),
            'lawyer': getObjectKey(obj, 'lawyer'),
            'mobile_phone': getObjectKey(obj, 'mobile_phone', true),
            'name': getObjectKey(obj, 'firstname') + ' ' + getObjectKey(obj, 'lastname'),
            'office_phone': getObjectKey(obj, 'office_phone', true),
            'origin': getObjectKey(obj, 'origin'),
            'postcode': getObjectKey(obj, 'postcode'),
            'quality': getObjectKey(obj, 'quality'),
            'responsible': getObjectKey(obj, 'responsible'),
            "languages": getObjectKey(obj, 'languages'),
            "speciality": getObjectKey(obj, 'speciality'),
            "biography": getObjectKey(obj, 'biography'),
            "distinctions": getObjectKey(obj, 'distinctions'),
            "credentials": getObjectKey(obj, 'credentials'),
            "publications": getObjectKey(obj, 'publications'),
            "academicExperiences": getObjectKey(obj, 'academicExperiences'),
            "professionalExperiences": getObjectKey(obj, 'professionalExperiences'),
        }

        if(isDefined(obj.company) && obj.company !== null){
            result['company']= {
                'id': getObjectKey(obj.company, 'id'),
                'name': getObjectKey(obj.company, 'name'),
                'avatar': getObjectKey(obj.company, 'avatar'),
            }
        }else{
            result['company'] = null
        }

        if(isDefined(obj.social_networks)){
            result['social_networks'] = []
            for(const social_network of obj.social_networks) {
                result['social_networks'].push(
                    {
                        'id': getObjectKey(social_network, 'id'),
                        'name': getObjectKey(social_network, 'name'),
                        'link': getObjectKey(social_network, 'link'),
                    }
                )
            }
        }else{
            result['social_networks'] = []
        }

        if(isDefined(obj.interests)){
            result['interests'] = []
            for(const interest of obj.interests) {
                result['interests'].push(
                    {
                        'id': getObjectKey(interest, 'id'),
                        'label': getObjectKey(interest, 'label'),
                    }
                )
            }
        }else{
            result['interests'] = null
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}
