import axiosRequest from "../../AuthenticatedRoute";
import { stringIsNotBlank } from "@/utils";
const namespaced = true;

export default {
  namespaced,
  state: {
    dataUser: null,
    dataLpclient: null,
    dataLpClients: null,
    siret: null,
    email: null,
  },
  getters: {
    getUserTemp(state) {
      return state.dataUser;
    },
    getLpClientTemp(state) {
      return state.dataLpclient;
    },
    getLpClientsTemp(state) {
      return state.dataLpClients;
    },
    getSiret(state) {
      return state.siret;
    },
    getEmail(state) {
      return state.email;
    },
  },
  mutations: {
    setUserTemp(state, user) {
      state.dataUser = user;
    },
    setLpClientTemp(state, lpClient) {
      state.dataLpclient = lpClient;
    },
    setLpClientsTemp(state, lpClients) {
      state.dataLpClients = lpClients;
    },
    setSiret(state, siret) {
      state.siret = siret;
    },
    setEmail(state, email) {
      state.email = email;
    },
  },
    actions:{
        postUser({ rootState, state }) {
            return new Promise((resolve)=>{
                axiosRequest({
                        method:'POST',
                        url:'/user/register',//API
                        headers: {
                            Authorization: 'Bearer '+rootState.account.userInfos.token
                        },
                        data:state.dataUser
                    }).then(response=>{
                        response.status===200 
                        resolve()
                    }).catch(error=>{   
                        console.error(error)
                    })
            })
        },
        postLpClient({rootState,state}){
            return new Promise((resolve,reject)=>{
                axiosRequest({
                        method:'POST',
                        url:'/user/lp_client/',//API
                        headers: {
                            Authorization: 'Bearer '+rootState.account.userInfos.token
                        },
                        data:state.dataLpclient
                    }).then(response=>{
                        response.status===200
                        resolve(response.data.data)
                    }).catch(error=>{
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getAllSpeciality({ rootState }) {
          return new Promise((resolve, reject) => {
            axiosRequest({
              method: "GET",
              url: "/user/getSpeciality/" + rootState.userModule.dataLpclient.id,
              headers: {
                Authorization: "Bearer " + rootState.account.userInfos.token,
              },
            })
              .then((response) => {
                if (response.status === 201) {
                  resolve(response.data.data);
                }
                reject();
              })
              .catch((error) => console.error(error));
          });
        },
        updateLpClientAdmin({ rootState, state, dispatch, commit}, {updatedData, avatar}) {
            return new Promise((resolve, reject) => {   
                const siret = state.dataLpclient.siret;
                axiosRequest({
                    method: 'PUT',
                    url: `/user/lp_client/updateBySuperAdmin/`+siret,
                    headers: {
                        Authorization: 'Bearer ' + rootState.account.userInfos.token
                    },
                    data:updatedData
                }).then(response => {
                    if (response.status === 201) {
                        const  newData = response.data.data[1]
                        state.dataLpClients = state.dataLpClients.map(item => item.id === newData.id ? newData : item)
                        state.dataLpclient = newData;
                        if (stringIsNotBlank(avatar)) {
                          dispatch('updateAvatar', { siret, avatar })
                        }
                      commit('setLpClientTemp', response.data.data);
                      resolve(response)
                  }else {
                    reject(new Error('Failed to update LP client'));
                  }
                }).catch(error => {
                    console.error(error);
                    reject(error);
                });
            });
        },
        //eslint-disable-next-line
        updateAvatar({}, {siret, avatar}){
          const method = "PUT"
          const newData = {}
          newData.avatar = avatar
  
          return new Promise((resolve, reject) => {
              axiosRequest({
                  method,
                  url: '/user/lp_client/update-avatar/'+siret,
                  data: newData
              }).then((response) => {
                  resolve(response)
              }).catch(error => {
                  reject(error)
              })
          })
        },
        affecter({rootState,getters}){
            return new Promise((resolve,reject)=>{
                axiosRequest({
                        method:'PUT',
                        url:'/user/lp_client/addUsers/'+getters.getSiret,
                        headers: {
                            Authorization: 'Bearer '+rootState.account.userInfos.token
                        },
                        data:{users:getters.getEmail}
                    }).then(response=>{
                        response.status===201 ?alert("Affected"): reject()
                        resolve()
                    }).catch(error=>{
                        console.error(error)
                    })
            })
        },
        getAllLpClients({rootState,commit}){
            return new Promise((resolve,reject)=>{
                axiosRequest({
                    method:'GET',
                    url:"/user/lp_client/list",
                    headers:{
                        Authorization: 'Bearer '+rootState.account.userInfos.token
                    }
                }).then(response=>{
                    response.status===201?commit('setLpClientsTemp',response.data.data):reject()
                    resolve()
                }).catch(error=>console.error(error))
            })
        },
        getAllLpClientUsers({rootState}){
            return new Promise((resolve,reject)=>{
                axiosRequest({
                    method:'GET',
                    url:"/user/lp_client/users/"+rootState.userModule.dataLpclient.id,
                    headers:{
                        Authorization: 'Bearer '+rootState.account.userInfos.token
                    }
                }).then(response=>{
                   if(response.status===201){
                       resolve(response.data.data)
                    }
                    reject()
                }).catch(
                    error=>console.error(error)
                )
            })
        }

    }
  }