import {
    ADD_CONVERSATION,
    ADD_CONVERSATION_SEARCH,
    ADD_FAV_CONVERSATION,
    ADD_MESSAGE,
    INSERT_MESSAGE,
    ADD_MESSAGES_FILE_SEARCH,
    ADD_MESSAGES_TAG_SEARCH,
    ADD_MESSAGES_TEXT_SEARCH,
    ADD_TAGS,
    DROP_CURRENT_CONVERSATION,
    DROP_CURRENT_PROJECT_STORE,
    EDIT_FAV_CONVERSATION,
    EDIT_MESSAGE,
    READ_MESSAGE,
    REMOVE_CONVERSATION,
    REMOVE_FAV_CONVERSATION,
    DELETE_MESSAGE,
    RESET_SEARCHING_ARRAY,
    RESET_SEARCHING_CONVERSATION,
    RESET_MESSAGE_TO_BE_EDITED,
    SET_CURRENT_CONVERSATION,
    SET_CURRENT_PROJECT,
    SET_FAV_BOOL,
    SET_HEIGHT_INPUT_CONTAINER,
    SET_META_LIST_MESSAGES,
    SET_SEARCHING_CONVERSATION,
    SET_MESSAGE_TO_BE_EDITED,
    SET_UNREAD_MESSAGES,
    UPDATE_SLUG_MESSAGE,
    UPDATE_CONVERSATION,
    TOGGLE_MESSAGES_LOADER,
    SET_MESSAGE_TO_BE_RESPONDED,
    RESET_MESSAGE_TO_BE_RESPONDED,
} from "../mutations-types";
import axiosRequest from "../../AuthenticatedRoute"
import {
    BASE_CONVERSATION_TOPIC,
    BASE_MESSAGE_TOPIC,
    MERCURE_ADDFAV_EVENT,
    MERCURE_CREATE_EVENT,
    MERCURE_DELETE_EVENT,
    MERCURE_REMOVEFAV_EVENT,
    MERCURE_UPDATE_EVENT,
    MESSENGER_SEPARATOR
} from "@/constant";
import {
    buildFormData,
    convertDateFormat,
    getObjectKey,
    internalLowerCase,
    isDefined,
    isValidObject,
    sortConversations,
    stringIsNotBlank
} from "@/utils";
const namespaced = true

const state = {
    currentProject: '',
    currentConversation: {
        name: '',
        slug: '',
        group:[],
        project: {
            codeID: '',
            name: '',
            description: '',
            picture: '',
            isBillable: '',
            inCharge: '',
            contact: '',
            status: '',
            users:   ''
        },
        isFav: '',
        currentPage: 1,
        totalPages: 1
    },
    conversations: [
        {
            createdAt:new Date (),
            date: new Date().toISOString().split('T')[0],
            isFav:false,name:'CHATBOT',opened:false,project:'',sender:'',time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        }
    ],
    messageToBeResponded:'',
    conversationsLoaded : false,
    conversationsFavorite: [],
    messages: [],
    messageToBeEdited: '',
    metaListMessages: {
        page: '',
        nbMessages: '',
        lastPage: ''
    },
    unreadMessages: [],
    messagesLoading : false,
    searching: false,
    searchingConversations: [],
    searchingMessagesText: [],
    searchingMessagesFile: [],
    searchingMessagesTag: [],
    searchingFiles: [],
    tags: [],
    tagLoaded: false,
    favBool: false,

    heightInputContainer: ''
}

const getters = {
    //MESAGES GETTERS
    searchingMessagesTextGetter: (state) => {
        return state.searchingMessagesText
    },

    searchingMessagesTagGetter: (state) => {
        return state.searchingMessagesTag
    },

    messagesConversation: (state) => (conversationSlug) => {
        return state.messages.filter(message => message.conversationSlug === conversationSlug)
    },

    lastMessagesConversation: (state, getters) => (conversationSlug) => {
        if(conversationSlug === '') {
            return []
        } else {
            return getters.messagesConversation(conversationSlug)[getters.messagesConversation(conversationSlug).length - 1]
        }
    },

    //CONVERSATIONS GETTERS
    personalConversation: (state, getters) =>  {
        const arrayConversations = state.conversations.filter(item => getObjectKey(item, 'project', true) === '') // We retrieve all conversation with empty project slug
        sortConversations(arrayConversations, getters.messagesConversation)
        return arrayConversations
    },

    personalConversationFavorite: (state, getters) => {
        const arrayConversations = state.conversations.filter(item => getObjectKey(item, 'project', true) === '' && item.isFav === true)
        sortConversations(arrayConversations, getters.messagesConversation)
        return arrayConversations
    },

    projectConversation: (state, getters) =>  {
        const arrayConversations = state.conversations.filter(item => getObjectKey(item, 'project', true) !== '') // We retrieve all conversation with no empty project slug
        sortConversations(arrayConversations, getters.messagesConversation)
        return arrayConversations
    },

    projectSlugConversation: (state, getters) => (projetSlug) => {
        const arrayConversations = state.conversations.filter(item => item.project === projetSlug)
        sortConversations(arrayConversations, getters.messagesConversation)
        return arrayConversations
    },

    projectConversationFavorite: (state, getters) => (projetSlug) => {
        const arrayConversations = state.conversations.filter(item => item.project === projetSlug && item.isFav === true)
        sortConversations(arrayConversations, getters.messagesConversation)
        return arrayConversations
    },
}

const mutations = {

    //Project
    [DROP_CURRENT_PROJECT_STORE](state) {
        state.currentProject = { name: '', slug: '', description: '', picture: '', isBillable: '', inCharge: '', contact: '', status: '', users: '' }
    },

    [SET_CURRENT_PROJECT](state, data){
        state.currentProject = data
    },

    //Conversation
    [DROP_CURRENT_CONVERSATION](state) {
        state.currentConversation = { name: '', group: '', slug: '', isFav: '', opened: '', currentPage: 1, totalPages: 1 }
    },

    [SET_CURRENT_CONVERSATION](state, { name, group, slug, isFav, opened, currentPage, totalPages }){
        state.currentConversation = { name, group, slug, isFav, opened, currentPage, totalPages }
    },

    [ADD_CONVERSATION](state, {conversations, onTop}) {
        for(const conversation of conversations) {
            if(state.conversations.filter(item => item.slug === conversation.slug).length === 0) {
                if(onTop){
                    state.conversations.unshift(conversation)
                }else{
                    state.conversations.push(conversation)
                }
            }
        }
        state.conversationsLoaded = true
    },

    [REMOVE_CONVERSATION](state, {slug}) {
        state.conversations = state.conversations.filter(item => item.slug !== slug)
    },

    [UPDATE_CONVERSATION](state, {conversation}) {
        state.conversations = state.conversations.map(item => item.slug === conversation.slug ? conversation : item)
    },

    //Tag
    [ADD_TAGS](state, {tags}) {
        for(const tag of tags) {
            const tagNameLength = state.tags.filter(item => item.name === tag.name).length
            if(tagNameLength === 0) {
                state.tags.push(tag)
            } else if (tagNameLength === 1) {
                state.tags = state.tags.map(item => item.name === tag.name ? tag : item)
            }
        }

        state.tagLoaded = true
    },

    //Message
    [ADD_MESSAGE](state, {messages}) {
        for(const message of messages) {
            if(state.messages.filter(item => item.slug === message.slug).length === 0) {
                state.messages.push(message)
            }
        }
    },

    [INSERT_MESSAGE](state, {messages}) {
        // insert message before message already present => inverse to have message in the good order
        for(const message of messages.reverse()) {
            if(state.messages.filter(item => item.slug === message.slug).length === 0) {
                state.messages.unshift(message)
            }
        }
    },

    [EDIT_MESSAGE](state, {slug, newMessage, tagSlugs, edited}) {
        state.messages.filter(message => message.slug === slug).map(message => {
            message.text = newMessage
            message.tags = tagSlugs
            message.edited = edited
        })
    },

    [UPDATE_SLUG_MESSAGE](state, {fictifSlug, message}) {
       state.messages = state.messages.map(m => m.slug === fictifSlug ? message[0] : m) //TODO: à optimiser
    },

    [READ_MESSAGE](state, {slug, email}) {
        let tmp = state.messages.filter(message => message.slug === slug)[0].isRead
        tmp = tmp.split(',')
        tmp.push(email)
        state.messages.filter(message => message.slug === slug).map(message => {
            message.isRead = tmp.join(',')
        })
    },

    [SET_MESSAGE_TO_BE_EDITED](state, message) {
        state.messageToBeEdited = message
    },

    [RESET_MESSAGE_TO_BE_EDITED](state) {
        state.messageToBeEdited = ''
    },

    [SET_META_LIST_MESSAGES](state, { page, nbMessages, lastPage}) {
        state.metaListMessages = { page, nbMessages, lastPage }
    },

    [DELETE_MESSAGE](state, {slug}) {
        state.messages = state.messages.filter(item => item.slug !== slug)
    },

    [SET_UNREAD_MESSAGES](state, unreadMessages) {
        state.unreadMessages = unreadMessages
    },

    [TOGGLE_MESSAGES_LOADER](state){
        state.messagesLoading = !state.messagesLoading;
    },

    //Favorites
    [SET_FAV_BOOL](state, boolFav) {
        state.favBool = !boolFav;
    },

    [ADD_FAV_CONVERSATION](state, {slug}) {
        state.conversations.filter(conversation => conversation.slug === slug).map(conversation => conversation.isFav = true)

        if(state.conversationsFavorite.filter(item => item.slug === slug).length === 0) {
            state.conversationsFavorite.push(state.conversations.filter(conversation => conversation.slug === slug)[0])
        }
    },

    [EDIT_FAV_CONVERSATION](state, {slug, boolFav}) {
        state.conversations.filter(conversation => conversation.slug === slug).map(conversation => {
            conversation.isFav = boolFav
        })
        state.conversationsFavorite.filter(conversation => conversation.slug === slug).map(conversation => {
            conversation.isFav = boolFav
        })
    },

    [REMOVE_FAV_CONVERSATION](state, {slug}) {
        state.conversations.filter(conversation => conversation.slug === slug).map(conversation => conversation.isFav = false)
        state.conversationsFavorite = state.conversationsFavorite.filter(conversation => conversation.slug !== slug)
    },

    [SET_SEARCHING_CONVERSATION](state) {
        state.searching = true;
    },

    [RESET_SEARCHING_CONVERSATION](state) {
        state.searching = false;
    },

    [RESET_SEARCHING_ARRAY](state) {
        state.searchingConversations = []
        state.searchingMessagesTag = []
        state.searchingMessagesText = []
        state.searchingMessagesFile = []
    },

    [ADD_CONVERSATION_SEARCH](state, {conversations}) {
        state.searchingConversations = []

        for(const conversation of conversations) {
            if(state.searchingConversations.filter(item => item.slug === conversation.slug).length === 0) {
                state.searchingConversations.push(conversation)
            }
        }

        state.conversationsLoaded = true
    },

    [ADD_MESSAGES_TEXT_SEARCH](state, {messages}) {
        state.searchingMessagesText = []

        for(const message of messages) {
            if(state.searchingMessagesText.filter(item => item.slug === message.slug).length === 0) {
                state.searchingMessagesText.push(message)
            }
        }
    },
    [RESET_MESSAGE_TO_BE_RESPONDED](state){
        state.messageToBeResponded=''
    },
    [SET_MESSAGE_TO_BE_RESPONDED](state,message){
        state.messageToBeResponded=message
    },

    [ADD_MESSAGES_FILE_SEARCH](state, {messages}) {
        state.searchingMessagesFile = []

        for(const message of messages) {
            if(state.searchingMessagesFile.filter(item => item.slug === message.slug).length === 0) {
                state.searchingMessagesFile.push(message)
            }
        }
    },

    [ADD_MESSAGES_TAG_SEARCH](state, {messages}) {
        state.searchingMessagesTag = []

        for(const message of messages) {
            if(state.searchingMessagesTag.filter(item => item.slug === message.slug).length === 0) {
                state.searchingMessagesTag.push(message)
            }
        }
    },

    [SET_HEIGHT_INPUT_CONTAINER](state, height) {
        state.heightInputContainer = height
    },

    setSlugCHATBOT(state,email){
        state.conversations[0].slug=email
        state.conversations[0].group=[email,"CHATBOT"]
    }
}

const actions = {
    setSlugCHATBOT({commit},data){
        commit('setSlugCHATBOT',data)
    },
    dropCurrentProjectStore({commit}) {
        commit(DROP_CURRENT_PROJECT_STORE)
    },

    async selectProject({commit, rootState}, {slug}) {
        const project = rootState.project.projects.filter(project => project.slug === slug)[0]
        await commit(SET_CURRENT_PROJECT, project)
    },

    // Conversation
    dropCurrentConversationStore({commit}) {
        commit(DROP_CURRENT_CONVERSATION)
    },

    async loadConversations({commit, dispatch}){
        dispatch('listenConversation')
        dispatch('listenMessage')
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/messenger/conversation/personalList'
            }).then(async response => {
                const conversationsList = response.data.data

                if(isDefined(conversationsList)) {

                    commit(ADD_CONVERSATION, {conversations: buildConversations(conversationsList)})
                    // for (let item of conversationsList) {
                    //     await dispatch('listConversationMessage', item.slug)
                    // }
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadConversation({commit}, {slug}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/messenger/conversation/read/'+slug
            }).then(async response => {
                const conversationsList = response.data.data

                if(isDefined(conversationsList)) {

                    commit(ADD_CONVERSATION, {conversations: buildConversations(conversationsList)})
                    // for (let item of conversationsList) {
                    //     await dispatch('listConversationMessage', item.slug)
                    // }
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadProjectConversations({commit}, {slug}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/messenger/conversation/projectList/'+slug
            }).then(async response => {
                const conversationsList = response.data.data

                if(isDefined(conversationsList)) {
                    commit(ADD_CONVERSATION, {conversations: buildConversations(conversationsList)})
                    // for (let item of conversationsList) {
                    //     await dispatch('listConversationMessage', item.slug)
                    // }
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // listConversationMessage({commit, rootState}, {conversationSlug, page, limit}) {
    listConversationMessage({commit, rootState}, conversation) {
        if(conversation.currentPage <= conversation.totalPages) {
            commit(TOGGLE_MESSAGES_LOADER)
            return new Promise((resolve, reject) => {
                axiosRequest({
                    method: 'POST',
                    url: '/messenger/message/list/'+conversation.slug,
                    data: buildFormData({
                        page: conversation.currentPage,
                        limit: 20
                    }),
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then(async response => {
                    const data = response.data.data

                    if(isDefined(data)) {
                        let messageBuild = buildMessages(data.data, rootState)
                        // commit(ADD_MESSAGE, {messages: messageBuild})
                        await commit(INSERT_MESSAGE, {messages: messageBuild})
                        // insert message before message already present => inverse to have message in the good order
                        conversation.currentPage++
                        conversation.totalPages = Math.ceil(data.total / 10)
                        await commit(UPDATE_CONVERSATION, {conversation})
                        await commit(SET_CURRENT_CONVERSATION, conversation)
                    }

                    resolve(response)
                }).catch(error => {
                    reject(error)
                }).finally(() => {
                    commit(TOGGLE_MESSAGES_LOADER)
                })
            })
        }
    },

    async selectConversation({commit, state, dispatch}, {slug, oldSlug}) {
        const conversation = state.conversations.filter(conversation => conversation.slug === slug)[0]
        await commit(SET_CURRENT_CONVERSATION, conversation)
        if(conversation.name !== 'CHATBOT'){
            await dispatch('listConversationMessage', conversation)
        }
        dispatch('isMessageRead', {slug: oldSlug})
    },

    async createPersonalConversation({commit, dispatch, state}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                url: '/messenger/conversation/createPersonal',
                data: buildFormData(data),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(async response => {
                const data = response.data.data

                if(isDefined(data)) {
                    const conversations = buildConversations([data])

                    commit(ADD_CONVERSATION, {conversations, onTop: true})

                    dispatch('selectConversation', {slug: data.slug, oldSlug: state.currentConversation.slug})

                    // await dispatch('listConversationMessage', item.slug)
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async createProjectConversation({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                url: '/messenger/conversation/createProjectConversation/' + data.projectSlug,
                data: buildFormData(data),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(async response => {
                const data = response.data.data

                if(isDefined(data)) {
                    const conversations = buildConversations([data])
                    commit(ADD_CONVERSATION, {conversations, onTop: true})

                    dispatch('selectConversation', {slug: data.slug, oldSlug: state.currentConversation.slug})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    //tags
    async loadTags({commit}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/messenger/tag/',
            }).then(response => {
                commit(ADD_TAGS, {tags: buildTags(response.data)})

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Message
    createMessageConversationSlug({commit, dispatch, rootState, state}, data) {
        return new  Promise((resolve, reject) => {

            const today = new Date()
            today.setHours( today.getHours()+(today.getTimezoneOffset()/-60) );
            const fictifSlug = data.conversationSlug.substring(0, 5) + Math.floor((Math.random() * 10000000)) + data.sender.substring(0, 5)
            data['slug'] = fictifSlug
            data['isRead'] = data.sender
            data['tags'] = []
            data['createdAt'] = {date: today.toJSON()}

            commit(ADD_MESSAGE, {messages: buildMessages([data], rootState)})

            axiosRequest({
                method: 'POST',
                url: '/messenger/message/'+state.currentConversation.slug,
                data: buildFormData(data),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    dispatch('isMessageRead', {slug: data.conversation.slug})

                    commit(UPDATE_SLUG_MESSAGE, {fictifSlug: fictifSlug, message: buildMessages([data], rootState)})

                    commit(ADD_TAGS, {tags: buildTags(data.tags)})
                }

                resolve(response)
            }).catch(error => {
                if (error){
                    commit (DELETE_MESSAGE, {slug: fictifSlug})
                }
                reject(error)
            })
        })
    },

    requestToTheBot({commit,rootState},data){
        const fictifSlug = rootState.account.userInfos.email.substring(0, 5) + Math.floor((Math.random() * 10000000)) + rootState.account.userInfos.email.substring(0, 5)
        var objData={
            fictifSlug:fictifSlug,
            text:data,
            isSender:true,
        }
        commit(ADD_MESSAGE, {messages: buildMessages([objData], rootState,true)})
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'POST',
                url:'/chatgpt/',
                data:{input:data},
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response=>{
                if(response.status===201){
                    const fictifSlug2 = rootState.account.userInfos.email.substring(0, 5) + Math.floor((Math.random() * 10000000)) + rootState.account.userInfos.email.substring(0, 5)
                    const data ={
                                    text:response.data.data,
                                    isSender:false,
                                    fictifSlug:fictifSlug2
                                }
                    if(isDefined(data)){

                        commit(ADD_MESSAGE, {messages: buildMessages([data], rootState,true)})
                        //commit(UPDATE_SLUG_MESSAGE, {fictifSlug: fictifSlug, message: buildMessages([data], rootState,true)})
    
                        //commit(ADD_TAGS, {tags: buildTags(data.tags)})
                    }
                    resolve(response)
                }
            }).catch(error=>{
                console.log(error)
                reject(error)
            })
        })

    },
    createMessageConversationProject({commit, dispatch, rootState}, data) {
        return new Promise((resolve, reject) => {

            const today = new Date()
            today.setHours( today.getHours()+(today.getTimezoneOffset()/-60) );
            const fictifSlug = data.conversationSlug.substring(0, 5) + Math.floor((Math.random() * 10000000)) + data.sender.substring(0, 5)
            data['slug'] = fictifSlug
            data['isRead'] = data.sender
            data['tags'] = []
            data['createdAt'] = {date: today.toJSON()}

            commit(ADD_MESSAGE, {messages: buildMessages([data], rootState)})

            axiosRequest({
                method: 'POST',
                url: '/messenger/message/'+data.conversationSlug,
                data: buildFormData(data),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {

                const data = response.data.data

                if(isDefined(data)) {
                    dispatch('isMessageRead', {slug: data.conversation.slug})

                    // commit(ADD_MESSAGE, {messages: buildMessages([data], rootState)})
                    commit(UPDATE_SLUG_MESSAGE, {fictifSlug: fictifSlug, message: buildMessages([data], rootState)})

                    commit(ADD_TAGS, {tags: buildTags(data.tags)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async editMessage({commit}, data) {
        return new Promise((resolve, reject) => {
            const method = "PUT"

            axiosRequest({
                method: method,
                url: '/messenger/message/'+data.slug,
                data: buildFormData(data, method, true),
            }).then(response => {
                const   result = response.data.data;

                if(isDefined(result)) {
                    commit(EDIT_MESSAGE, {
                        slug: result.slug,
                        newMessage: result.content,
                        tagSlugs: result.tags,
                        edited: result.edited
                    })

                    commit(ADD_TAGS, {tags: buildTags(response.data.data.tags)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    sendMessageByEmail({commit},data) {
        commit;
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/ged/file/sendMail',
                data: buildFormData(data),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                if(response.status===-1){
                    reject(response.message)
                }else{
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    async deleteMessage({commit}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/messenger/message/'+data.slug,
            }).then(response => {
                commit(DELETE_MESSAGE, {slug: data.slug})

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getUnreadMessages({commit}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/messenger/message/list/user/unRead',
            }).then(response => {
                if(isDefined(response.data.data)){
                    commit(SET_UNREAD_MESSAGES, response.data.data)
                }else{ 
                    commit(SET_UNREAD_MESSAGES, [])
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    setMessageToBeResponded({commit}, message) {
        commit(SET_MESSAGE_TO_BE_RESPONDED, message)
    },

    resetMessageToBeResponded({commit}) {
        commit(RESET_MESSAGE_TO_BE_RESPONDED)
    },

    setMessageToBeEdited({commit}, message) {
        commit(SET_MESSAGE_TO_BE_EDITED, message)
    },

    resetMessageToBeEdited({commit}) {
        commit(RESET_MESSAGE_TO_BE_EDITED)
    },

    //Favoris
    setFavBoolAction({commit}, boolFav) {
      commit(SET_FAV_BOOL, boolFav)
    },

    async addFav({commit}, {slug}) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()
            formData.append('slugs', slug);

            axiosRequest({
                method: 'POST',
                url: '/messenger/conversation/addFav',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                commit(ADD_FAV_CONVERSATION, {slug});

                commit(EDIT_FAV_CONVERSATION, {
                    slug: {slug},
                    boolFav: true
                })

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async removeFav({commit}, {slug}) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()
            formData.append('slugs', slug);

            axiosRequest({
                method: 'POST',
                url: '/messenger/conversation/removeFav',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                commit(REMOVE_FAV_CONVERSATION, {slug});

                commit(EDIT_FAV_CONVERSATION, {
                    slug: {slug},
                    boolFav: false
                })

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    resetSearchingArray({commit}) {
        commit(RESET_SEARCHING_ARRAY)
    },

    searchMessagesText({commit, rootState, state}, {searchQuery, conversationSlug = ''}) {
        return new Promise((resolve, reject) => {

            if(searchQuery.trim() !== '') {
                let formData = new FormData()
                formData.append('query', searchQuery)

                const
                    selectedProject = getObjectKey(state.currentProject, 'slug', true)

                if(isDefined(conversationSlug) && conversationSlug !== ''){
                    formData.append('conversationSlug', conversationSlug)
                }

                if(isDefined(selectedProject) && selectedProject !== ''){
                    formData.append('projectSlug', selectedProject)
                }

                commit(SET_SEARCHING_CONVERSATION)

                axiosRequest({
                    method: 'POST',
                    url: '/messenger/message/search/search',
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(response => {
                    let result = response.data.data
                    if (isDefined(result)) {
                        let messagesText = [],
                            messagesFile = [],
                            queryLowerCase = searchQuery.toLowerCase()

                        for(let item of result){

                            const files = getObjectKey(item, 'files')
                            if(isValidObject(files)){
                                const file_name = internalLowerCase(getObjectKey(files[0], 'name', true))
                                if(file_name.includes(queryLowerCase)) {
                                    messagesFile.push(item)
                                }
                            }

                            const msg_name = internalLowerCase(getObjectKey(item, 'content', true))
                            if(isValidObject(msg_name) && msg_name.includes(queryLowerCase)){
                                messagesText.push(item)
                            }
                        }

                        commit(ADD_MESSAGES_TEXT_SEARCH, {messages: buildMessages(messagesText, rootState)})
                        commit(ADD_MESSAGES_FILE_SEARCH, {messages: buildMessages(messagesFile, rootState)})
                    }

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            }
        })
    },

    searchMessagesTag({commit, rootState}, {searchQuery, tagSlugs}) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()
            formData.append('query', searchQuery);
            formData.append('tagSlugs', tagSlugs);

            commit(SET_SEARCHING_CONVERSATION)

            axiosRequest({
                method: 'POST',
                url: '/messenger/message/search/search',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    commit(ADD_MESSAGES_TAG_SEARCH, {messages: buildMessages(data, rootState)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    searchConversationName({commit, rootState}, {searchQuery}) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()
            formData.append('query', searchQuery);

            commit(SET_SEARCHING_CONVERSATION)

            axiosRequest({
                method: 'POST',
                url: '/messenger/conversation/search/search',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    commit(ADD_CONVERSATION_SEARCH, {conversations: buildConversations(data, rootState)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    stopSearch({commit}){
        commit(RESET_SEARCHING_CONVERSATION)
    },

    //Read / No Read Message
    readMessage({commit, rootState, dispatch}, {messageSlug}) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()
            formData.append('slugs', messageSlug);

            axiosRequest({
                method: 'POST',
                url: '/messenger/message/conversation/messages/read',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {

                for(let slug of messageSlug.split(',')) {
                    commit(READ_MESSAGE, {
                        slug: slug,
                        email: rootState.account.userInfos.email
                    })
                }

                dispatch('getUnreadMessages')
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    isMessageRead({rootState, dispatch, state}, slug) {
        let messageConversation = state.messages.filter(message => message.conversationSlug === slug.slug)
        let messageNoRead = []

        for (let message of messageConversation) {
            if( message.conversationSlug !==rootState.account.userInfos.email && message.isRead.split(',').filter(item => item === rootState.account.userInfos.email).length === 0) {
                messageNoRead.push(message.slug)
            }
        }

        if(messageNoRead.length > 0) {
            dispatch('readMessage', {messageSlug: messageNoRead.join(',')})
        }
    },

    loadConversationBySlug({commit}, {slug}) {
        const method = "GET"
        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/messenger/conversation/read/'+slug
            }).then(async response => {
                const data = response.data.data

                if(isDefined(data)) {
                    commit(ADD_CONVERSATION, {conversations: buildConversations([data]), onTop: true})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    loadMessageBySlug({commit, rootState}, {slug}) {
        const method = "GET"
        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/messenger/message/'+slug
            }).then(async response => {
                const data = response.data.data

                if(isDefined(data)) {
                    const messages = buildMessage(data, rootState, true)

                    commit(ADD_MESSAGE, {messages})
                    commit(ADD_TAGS, {tags: buildTags(data.tags)})
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async listenMessage({rootState, dispatch}) {
        // if(!state.listeningMercureMsg) {
            const userEmail = rootState.account.userInfos.email

            if (stringIsNotBlank(userEmail)) {
                const
                    eventURL = process.env.VUE_APP_MERCURE_SERVER_URL + BASE_MESSAGE_TOPIC + '/' + userEmail,

                    eventSource = new EventSource(eventURL, {
                        headers: {
                            'Authorization': 'Bearer ' + rootState.account.userInfos.mercureToken
                        }
                    })

                eventSource.onmessage = async event => {
                    /**
                     * This method is called when we received an mercure's update on this chanel
                     */
                    const
                        data = JSON.parse(event.data),
                        mercureEvent = getObjectKey(data, 'event', true),
                        slug = getObjectKey(data, 'data', true)


                    switch (mercureEvent){
                        case 'CREATE':
                            await dispatch('loadMessageBySlug', {slug})
                            await dispatch('getUnreadMessages')
                            break
                        default:
                            console.error('this event is not yet managed')
                    }

                    // if (mercureEvent === MERCURE_CREATE_EVENT) {
                    //     await dispatch('loadMessageBySlug', {slug})
                    // } else if (mercureEvent === MERCURE_UPDATE_EVENT) {
                    //     console.log('appel mutation update messenge')
                    // }
                }

                eventSource.onerror = err => {
                    console.error('File ... Something went wrong ', err);
                }
                // commit(TOGGLE_LISTENING_MERCURE_MSG)
            }
        // }
    },

    async listenConversation({rootState, commit, dispatch}) {
        // if(!state.listeningMercureConversation) {
            //Subscribing to conversation messages updates
        const userEmail = rootState.account.userInfos.email

            if (stringIsNotBlank(userEmail)) {
                const
                    eventURL = process.env.VUE_APP_MERCURE_SERVER_URL + BASE_CONVERSATION_TOPIC + '/' + userEmail,

                    eventSource = new EventSource(eventURL, {
                        headers: {
                            'Authorization': 'Bearer ' + rootState.account.userInfos.mercureToken
                        }
                    })

                eventSource.onmessage = async event => {
                    // Will be called every time an update is published by the server
                    const
                        data = JSON.parse(event.data),
                        mercureEvent = getObjectKey(data, 'event', true),
                        slug = getObjectKey(data, 'data', true)

                    if(mercureEvent === MERCURE_CREATE_EVENT){
                        await dispatch('loadConversationBySlug', {slug})
                    }else if(mercureEvent === MERCURE_UPDATE_EVENT){
                        console.log('updating with new params ... ')
                    }else if(mercureEvent === MERCURE_DELETE_EVENT){
                        commit(REMOVE_CONVERSATION, {slug})
                    }else if(mercureEvent === MERCURE_ADDFAV_EVENT){
                        commit(ADD_FAV_CONVERSATION, {slug})
                    }else if(mercureEvent === MERCURE_REMOVEFAV_EVENT){
                        commit(REMOVE_FAV_CONVERSATION, {slug})
                    }
                }

                eventSource.onerror = err => {
                    console.error('Something went wrong ', err);
                }

                // commit(TOGGLE_LISTENING_MERCURE_CONVERSATION)
            }
        // }
    },

    setHeightInputContainer({commit}, value) {
        commit(SET_HEIGHT_INPUT_CONTAINER, value)
    }
}

function buildConversations(data) {
    let result = []

    for (const item of data) {
        result.push(buildConversation(item))
    }

    return result
}

function buildConversation(item, returnArray = false){
    let result = null

    if(isDefined(item)){
        let createdAt = ''

        if (item.createdAt) {
            createdAt = (typeof item.createdAt === 'object') ? item.createdAt.date : item.createdAt;
        }

        const project = getObjectKey(item, "project")

        result = {
            "name": getObjectKey(item, "name"),
            "slug": getObjectKey(item, "slug"),
            "sender": getObjectKey(item, "sender"),
            "date": convertDateFormat(createdAt),
            "time": createdAt.substring(11, 16),
            "createdAt": createdAt,
            "group": getObjectKey(item, "group").split(MESSENGER_SEPARATOR),
            "project": getObjectKey(project, 'codeID', true), //For optimisation, we just store the project codeID
            "opened": false,
            "isFav": getObjectKey(item, "isFav"),
            "lastMessage": getObjectKey(item, "lastMessage"),
            "currentPage": 1,
            "totalPages": 1,
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildMessages(data, rootState,CHATBOTCASE=false){
    let result = []

    for(const item of data){
        result.push(buildMessage(item, rootState,false,CHATBOTCASE))
    }

    return result
}

function buildMessage(item, rootState, returnArray = false,CHATBOTCASE=false){
    let result = null
    

    if(CHATBOTCASE){
        return {
            isSender:item.isSender,
            date:new Date().toISOString().split('T')[0],
            time:new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            text:item.text ,
            conversationSlug:rootState.account.userInfos.email,
            slug:item.fictifSlug,
        }
    }

    if(isDefined(item)){
        let createdAt = ''

        if (item.createdAt) {
            createdAt = (typeof item.createdAt === 'object') ? item.createdAt.date : item.createdAt;
        }

        result = {
            "slug": getObjectKey(item, "slug"),
            "text": getObjectKey(item, "content"),
            "sender": getObjectKey(item, "sender"),
            "isSender": item.sender === rootState.account.userInfos.email,
            "date": convertDateFormat(createdAt),
            "time": createdAt.substring(11, 16),
            "createdAt": createdAt,
            "file": getObjectKey(item, "files"),
            "isRead": getObjectKey(item, "isRead"),
            "tags": getObjectKey(item, "tags"),
            "status": getObjectKey(item, "status"),
            "edited": getObjectKey(item, "edited"),
        }

        if(isDefined(item.conversation) && item.conversation !== null) {
            result['receiver'] = item.conversation.group
            result['conversationSlug'] = item.conversation.slug
        } else if (isDefined(item.conversationSlug)) {
            result['conversationSlug'] = item.conversationSlug
        }else{
            result['receiver'] = null
            result['conversationSlug'] = null
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildTags(data) {
    let tags = []

    for (let tag of data) {
        tags.push({
            name: tag.name,
            slug: tag.slug
        })
    }

    return tags
}

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
}
