import {reverseDate,convertMinutesToHHMM} from "./timeFormatUtils"


export function setProjectToTask(task,slugOfProject){
    task['project']=slugOfProject
    task['relationType']='Project'
    task['url']=slugOfProject
    return task
}

export function convertTaskToSend(task,noTypeState=true){
    task['id']=parseInt(task['id'])
    if(noTypeState){
        task['type']=task['taskTypeState']
        delete task['taskTypeState']
    }
    task['scheduledDate']=task['startDate']
    task['deadline']=task['endDate']
    task['responsable']=task['userName']
    task['percent']=task['progress']
    task['workload']=parseInt(task['workload'])
    delete task['userName']
    delete task['startDate']
    delete task['endDate']
    delete task['progress']
    return task
}



function getRidOfRelationTypeUrl(url){
    if(url!==null){
        var temp=url
        temp=temp.replace('/project/view/','')
        return temp
    }
    return ""
}

export function  getToDosView(todos){
    let newToDos=[]
    todos.forEach(todo => {
        todo['todos'].map((element)=>{
            var temp ={
                id:element.id,title:element.title,summary:element.summary,
                startDate:reverseDate(element.scheduledDate),
                endDate:reverseDate(element.deadline),
                status:element.status,progress:element.percent,url:getRidOfRelationTypeUrl(element.url),
                taskTypeState:element.type,workload:element.workload,
                dependency:element.dependency,teamName:todo['teamName'],
                author:element.author,duration:element.minutesWorked?convertMinutesToHHMM(element.minutesWorked):"00 : 00",
                isTodo:true,responsible:todo['userName'],responsibleEmail:todo['userEmail'],timeValidation:element.timeValidation
            }
            if(Object.prototype.hasOwnProperty.call(todo, 'teamName')){
                temp['teamName']=todo['teamName']
            }
            if(Object.prototype.hasOwnProperty.call(todo, 'externalName')){
                temp['externalName']=todo['externalName']
            }

            newToDos.push(temp)
            return temp
        })
    })
    return newToDos
}

export function getImprovisedTaskView(tasks){
    return tasks.map((element)=>{
        return getImprovisedTaskFormat(element)
    
    })
}

export function getTodoTaskFormatAfterUpdate(element){

    var temp= {
        id:element.id,title:element.title,summary:element.summary,
        startDate:reverseDate(element.scheduledDate),
        endDate:reverseDate(element.deadline),
        status:element.status,progress:element.percent,url:getRidOfRelationTypeUrl(element.url),
        taskTypeState:element.type,workload:element.workload,
        dependency:element.dependency,
        author:element.createdBy.firstname+" "+element.createdBy.lastname,duration:element.minutesWorked?convertMinutesToHHMM(element.minutesWorked):"00 : 00",
        isTodo:true,responsible:element.responsable.firstname+" "+element.responsable.lastname,responsibleEmail:element.responsable.email,timeValidation:element.timeValidation
    }
        
    if(Object.prototype.hasOwnProperty.call(element, 'team')){
        temp['teamName']=element.team.name
    }
    return temp
    }

export function getImprovisedTaskFormat(element){
    return {
        note:element.note,
        idTask:element.id,
        responsibleEmail:element.user.email,
        responsible:element.user.firstname+" "+element.user.lastname,
        title:element.type,startDate:element.startDate,endDate:element.endDate?element.endDate:"",
        duration:element.minutesWorked?convertMinutesToHHMM(element.minutesWorked):'00 : 00',
        status:element.status,teamName:element.team.name,isTodo:false,timeValidation:element.timeValidation
    }
}

export function isUserResponsibleForTask(task,email){
    if(task['responsibleEmail']===email){
        return true
    }
    return false
}