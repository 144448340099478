/* eslint-disable no-unused-vars */
import {
    AUTHENTIFICATION_IN_PROGRESS,
    SET_CONNECTED,
    SET_PARTNER,
    SET_SUBSCRIPTION_PLAN,
    LOGIN,
    LOGOUT,
    UPDATE_CURRENT_USER,
    GENERATE_QR_CODE,
    LIST_LP_CLIENTS,
    GET_COMPANY,
    GET_SELECTED_LPCLIENT,
    LIST_CONTACTS,

    ADD_GLOBAL_NOTIFICATION,
    ADD_NEW_NOTIFICATION,
    REMOVE_GLOBAL_NOTIFICATION, ADD_USER, ADD_COWORKERS
} from "../mutations-types";
import axiosRequest from "../../AuthenticatedRoute";
import { buildFormData, dropVueStorage, getObjectKey, getRandomColor, isDefined, saveTokenExpiration, capitalize, stringIsNotBlank } from "@/utils";
import { avatarColors, MERCURE_PROJECT_NOTIFICATION_TOPIC, LEGAL_SUCCESS, LEGAL_TEAMS } from "@/constant";
const axios = require('axios');

const namespaced = true

const state = {
    userInfos: {
        avatar: null,
    },

    listUsers: [],
    listCoworkers: [],
    listLpClients: [],

    selectedLPClient: null,
    company: null,
    listContacts: [],

    globalNotification: [],

    flagLogin:false
}
const getters={
    getUserFullName(state){
        return state.userInfos.firstname + " " + state.userInfos.lastname
    },
    getListUsersForRecommendation(state, ){
        
        var users= state.listUsers.map(element=>{
            element['category']='Conseillés'
            element['fullname']=element.firstname+" "+element.lastname
            return element
        })
        var contacts=state.listContacts.map(element=>{
            element['category']='Contacts'
            element['fullname']=element.firstname+" "+element.lastname
            return element
        })
        var coworkers=state.listCoworkers.map(element=>{
            element['category']='Collaborateurs'
            element['fullname']=element.firstname+" "+element.lastname
        })

        return [...users,...contacts,...coworkers]
    }
}

const mutations = {
    [LOGIN](state, {email, name, token, mercureToken, isAdmin, color,isLawyer}){
        state.userInfos = {email, name, token, mercureToken, isAdmin, color, isLawyer}
    },

    [LOGOUT](state) {
        state.userInfos = {}
    },
    updateBio(state,bioObj){
        state.userInfos.biography=bioObj.biography
    },
    [ADD_USER](state, {users}){
        for(const user of users){
            if(state.listUsers.filter(item => item.id === user.id).length === 0){
                state.listUsers.push(user)
            }
        }
    },

    [ADD_COWORKERS](state, {users}){
        for(const user of users){
            if(state.listCoworkers.filter(item => item.id === user.id).length === 0 && user.isEmployee === true){
                state.listCoworkers.push(user)
            }
        }
    },

    [UPDATE_CURRENT_USER](state, data) {
        for (const item in data){
            state.userInfos[item] = data[item]
        }

        if(isDefined(state.userInfos.firstname) && state.userInfos.firstname.trim() !== '' &&
            isDefined(state.userInfos.lastname) && state.userInfos.lastname.trim() !== ''){
            state.userInfos.initial = state.userInfos.firstname[0]+ ' ' + state.userInfos.lastname[0]
            state.userInfos.name = state.userInfos.firstname + ' ' + state.userInfos.lastname
        } else {
            state.userInfos.initial = 'LL'
        }
    },

    [GENERATE_QR_CODE](state, {qrCode, activated}){
        state.userInfos.qrCode = qrCode;
        state.userInfos.qrCodeActivated = activated;
    },

    [LIST_LP_CLIENTS](state, data){
        state.listLpClients = data;
    },

    [GET_COMPANY](state, data){
        state.company = data;
    },

    [GET_SELECTED_LPCLIENT](state, data){
        state.selectedLPClient = data;
    },

    [LIST_CONTACTS](state, data){
        state.listContacts = data;
    },


    [ADD_GLOBAL_NOTIFICATION](state, data){
        for(const globalItem of data){
            if(state.globalNotification.filter(item => item.id === globalItem.id).length ===0 ){
                state.globalNotification.push(globalItem)
            }else{ // we handle update here

            }
        }
    },
    [ADD_NEW_NOTIFICATION](state, data){
        if(state.globalNotification.filter(item => item.id === data.id).length ===0 ){
            state.globalNotification.unshift(data)
        }
    },
    [REMOVE_GLOBAL_NOTIFICATION](state, {id}){
        state.globalNotification = state.globalNotification.filter(item => item.id !== id)
    },
    updateAvatar(state,avatar){
        state.userInfos.avatar=avatar
    }
}

const actions = {
    signup(commit, accountInfos) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()
            for(let index in accountInfos) {
                formData.append(index,accountInfos[index]);
            }

            axiosRequest({
                method: 'POST',
                url: '/company/create',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {

                commit(LOGIN, {
                    email: response.data.user.email,
                    password: accountInfos.password
                })
           
                commit(SET_CONNECTED)

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    login({commit, dispatch}, data) {
        const loginData = data
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/login',
                data: buildFormData(data, 'POST', true)
            }).then(response => {
                const data = response.data

                if(isDefined(data)) {
                    commit(UPDATE_CURRENT_USER, {
                        token: getObjectKey(data, 'token', true),
                        color: "#00244D",
                        email: loginData.username,
                    })
                    dispatch('messenger/setSlugCHATBOT',loginData.username,{root:true})
                    commit(AUTHENTIFICATION_IN_PROGRESS, null, {root: true});
                    dispatch('listUsers')
                    dispatch('readUser')

                    saveTokenExpiration()
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line
    verifyCaptchaToken({}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: `/user/verifyCaptcha?token=${data}`,
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    
    twoFactor({commit}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/2fa',
            }).then(response => {
                const qrCode  = response.data.qrCode;
                const activated = response.data.QrCodeActivated;
                commit(GENERATE_QR_CODE, {qrCode, activated});
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    checkTwoFactorCode({commit}, loginCode) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/verify',
                data:  { code: loginCode},
            }).then(response => {
                if(response){
                    const data = response.data;
                    commit(SET_CONNECTED, null, {root: true});
                    if(data.isEmployee === false){
                        commit(SET_PARTNER, null, {root: true});
                    }
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    setLPClientSubscriptionPlan({commit}, plan) {
        switch (plan){
            case 'LEGALSUCCESS':
                commit(SET_SUBSCRIPTION_PLAN, LEGAL_SUCCESS, {root: true});
                break;
            case 'LEGALTEAMS':
                commit(SET_SUBSCRIPTION_PLAN, LEGAL_TEAMS, {root: true});
                break;
            default:
                commit(SET_SUBSCRIPTION_PLAN, LEGAL_SUCCESS, {root: true});
                break;
        }
    },

    // eslint-disable-next-line 
    resetPassword({}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/reset-password',
                data: data,
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    checkRpToken({_},data){
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'POST',
                url:'/user/rpToken',
                data:data
            }).then((resp)=>{
                
                resolve(resp)
                
            }).catch(err=>{reject(err)}) 
        })
    },
    sendForgotPasswordEmail({_},data){
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'POST',
                url:'/user/forgot-password',
                data:data
            }).then((resp)=>{
                
                resolve(resp)
                
            }).catch(err=>{reject(err)})
        })
    },
    listLPClients({commit}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/user/getLPClients',
            }).then(response => {
                const data = response.data.LPClients;
                commit(LIST_LP_CLIENTS, buildLPClients(data));
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    readSelectedLPClient({state, commit, dispatch}, {id}) {
        return new Promise((resolve, reject) => {

            axiosRequest({
                method: 'GET',
                url: '/user/lp_client/getLPClient/'+id,
                headers: {
                    Authorization: 'Bearer '+state.userInfos.token
                },
            }).then(response => {
                const data = response.data

                if(isDefined(data) && isDefined(data.LPClients)){
                    const lpClient = buildSelectedLPClient(data.LPClients)

                    commit(UPDATE_CURRENT_USER, {lpClient: lpClient})

                    commit(GET_SELECTED_LPCLIENT, lpClient);
                    dispatch('setLPClientSubscriptionPlan', lpClient.offer)
                }


                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getCompany({commit}, {id}) {
        return new Promise((resolve, reject) => {

            axiosRequest({
                method: 'GET',
                url: '/user/getCompany/'+ id,
            }).then(response => {
                const data = response.data.Company;
                commit(GET_COMPANY, buildCompany(data));
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    listContacts({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/user/getContacts/'+ id,
            }).then(response => {
                const data = response.data.Contacts

                commit(LIST_CONTACTS, buildContacts(data))

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    listUsers({commit, state}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/list',
                data: {lp_client: state.selectedLPClient?.id},
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    commit(ADD_USER, {users: buildUsers(response.data.data)})
                    commit(ADD_COWORKERS, {users: buildUsers(response.data.data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    listPartnerUsers({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/user/partner/list/'+id,
            }).then(response => {
                const data = response.data.data

                if(isDefined(data)) {
                    commit(ADD_USER, {users: buildUsers(response.data.data)})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    readUser({state, commit, dispatch}) {
        return new Promise((resolve, reject) => {

            axiosRequest({
                method: 'GET',
                url: '/user/read',
                headers: {
                    Authorization: 'Bearer '+state.userInfos.token
                },
            }).then(async response => {
                const data = response.data.data

                await commit(UPDATE_CURRENT_USER, buildUser(data))
                dispatch('profile/setBioInProfile',state.userInfos.biography,{root:true})
                dispatch('setLPClientSubscriptionPlan', state.userInfos.lpClient.offer)
                dispatch('listGlobalNotifications')

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    logout({commit}) {
        commit(LOGOUT)

        dropVueStorage()
    },

    async listGlobalNotifications({commit}) {
        return new Promise((resolve, reject) => {

            axiosRequest({
                method: 'GET',
                url: '/user/notification/listUnRead',
            }).then(response => {
                const data = response.data.data;

                if(isDefined(data)){
                    commit(ADD_GLOBAL_NOTIFICATION, buildGlobalNotifications(data));
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    async readGlobalNotifications({commit}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/notification/read',
                data: buildFormData(data),
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {

                commit(REMOVE_GLOBAL_NOTIFICATION, {id: parseInt(data.ids)})

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updateBioInAcc({commit},bio){
        commit('updateBio',bio)
    },

    async uploadImageUser({commit}, { avatar}){
        const method = "PUT"
        const newData = {}
        newData.avatar = avatar
        return new Promise((resolve, reject) => {
            axiosRequest({
                method,
                url: '/user/update-avatar',
                data: newData
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(UPDATE_CURRENT_USER, {avatar: data.avatar})
                }

                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line
    contactSupport({}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/contactSupport',
                data: buildFormData(data, 'POST', true)
            }).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    listenForNewNotification({rootState, commit, dispatch}){
        const userEmail = rootState.account.userInfos.email
    
        if(stringIsNotBlank(userEmail)) {
            const eventURL = process.env.VUE_APP_MERCURE_SERVER_URL + MERCURE_PROJECT_NOTIFICATION_TOPIC + '/' + userEmail,
            eventSource = new EventSource(eventURL, {
                headers: {
                    'Authorization': 'Bearer ' + rootState.account.userInfos.mercureToken
                }
            })

            eventSource.onmessage = async event => {
                // Will be called every time an update is published by the server
                const data = JSON.parse(event.data)
                if(data){
                    commit(ADD_NEW_NOTIFICATION, buildGlobalNotification(data.data));
                }
            }

            eventSource.onerror = err => {
                console.error('Notification listener lost connection...', err);
            }
        }
    }
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}

function buildUsers(data){
    let result = []

    for(const item of data){
        result.push(buildUser(item))
    }

    return result
}


function buildUser(item, returnArray = false) {
    let result = null

    if(isDefined(item)){
   


        result = {
            'id': parseInt(getObjectKey(item, 'id')),
            'firstname': capitalize(getObjectKey(item, 'firstname')),
            'lastname': getObjectKey(item, 'lastname').toUpperCase(),
            'name': capitalize(getObjectKey(item, 'firstname')) + ' ' + getObjectKey(item, 'lastname').toUpperCase(),
            'email': getObjectKey(item, 'email'),
            'role': getObjectKey(item, 'role'),
            'isAdmin': getObjectKey(item, 'isAdmin'),
            'is_lawyer': getObjectKey(item, 'isLawyer'),
            'isContact': getObjectKey(item, 'isContact'),
            'isEmployee': getObjectKey(item, 'isEmployee'),
            'color': getRandomColor(avatarColors),
            'speciality': getObjectKey(item, 'speciality'),
            'avatar': getObjectKey(item, 'avatar'),
            'officePhone': getObjectKey(item, 'officePhone'),
            'mobilePhone': getObjectKey(item, 'mobilePhone'),
            'address': getObjectKey(item, 'address'),
            'additionalAddress': getObjectKey(item, 'additionalAddress'),
            'city': getObjectKey(item, 'city'),
            'postcode': getObjectKey(item, 'postcode'),
            'hourlyWage': getObjectKey(item, 'hourlyWage'),
            'targetHours': getObjectKey(item, 'targetHours'),
            'fonction': getObjectKey(item, 'function'),
            'languages':getObjectKey(item, 'languages'),
            'biography':getObjectKey(item,'biography')
            }

        if(getObjectKey(item, 'country')){
            result ["country"] = getObjectKey(item, "country")
        }else{
           result ["country"] = {
                'id': getObjectKey(item, 'country_id'),
                'label': getObjectKey(item, 'country_label'),
                'iso_code': getObjectKey(item, 'country_iso_code')
            }
        }


        if(isDefined(item.lpClient)){
            result['lpClient'] = item.lpClient
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildGlobalNotifications(data){
     let result = []

     for(const item of data){
         result.push(buildGlobalNotification(item))
     }

     return result
}

function buildGlobalNotification(item, returnArray = false){
    let result = null

    if(isDefined(item)){

        result = {
            "id": parseInt(getObjectKey(item, 'id')),
            "content": getObjectKey(item, 'content'),
            "isRead": getObjectKey(item, 'isRead'),
            "type": parseInt(getObjectKey(item, 'type')),
            "createdAt": getObjectKey(item.createdAt, 'date'),
            "url": getObjectKey(item, 'url') ? getObjectKey(item, 'url') : null,
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildSelectedLPClient(item, returnArray = false){
    let result = null

    if(isDefined(item)){

        result = {
            'id': parseInt(getObjectKey(item, 'id')),
            'name': getObjectKey(item, 'name').toUpperCase(),
            'siret': getObjectKey(item, 'siret'),
            'avatar': getObjectKey(item, 'avatar'),
            'offer': getObjectKey(item, 'offer'),
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildLPClients(data){
    let result = []

    for(const item of data){
        result.push(buildLPClient(item))
    }

    return result
}

function buildLPClient(item, returnArray = false) {
    let result = null

    if(isDefined(item)){

        result = {
            'id': parseInt(getObjectKey(item, 'id')),
            'name': getObjectKey(item, 'name'),
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}


function buildCompany(item, returnArray = false){
    let result = null

    if(isDefined(item)){

        result = {
            'id': parseInt(getObjectKey(item, 'id')),
            'name': getObjectKey(item, 'name').toUpperCase(),
            'email': getObjectKey(item, 'email'),
            'address': getObjectKey(item, 'address'),
            'additional_address': getObjectKey(item, 'additional_address'),
            'city': getObjectKey(item, 'city').toUpperCase(),
            'postcode': getObjectKey(item, 'postcode'),
            'office_phone': getObjectKey(item, 'office_phone'),
            'fax_number': getObjectKey(item, 'fax_number'),
            'website': getObjectKey(item, 'website'),
            'siret': getObjectKey(item, 'siret'),
            'vat': getObjectKey(item, 'vat'),
            'nace_code': getObjectKey(item, 'nace_code'),
            'avatar': getObjectKey(item, 'avatar'),
            'is_prospect': getObjectKey(item, 'is_prospect'),
            'is_customer': getObjectKey(item, 'is_customer'),
            'is_supplier': getObjectKey(item, 'is_supplier'),
            'is_cabinet': getObjectKey(item, 'is_cabinet'),
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}

function buildContacts(data){
    let result = []

    for(const item of data){
        result.push(buildContact(item))
    }

    return result
}

function buildContact(item, returnArray = false) {
    let result = null

    if(isDefined(item)){

        result = {
            'id': parseInt(getObjectKey(item, 'id')),
            'firstname': capitalize(getObjectKey(item, 'firstname')),
            'lastname': getObjectKey(item, 'lastname').toUpperCase(),
            'email': getObjectKey(item, 'email'),
            'function': getObjectKey(item, 'function'),
            'city': getObjectKey(item, 'city'),
            'officePhone': getObjectKey(item, 'officePhone'),
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}