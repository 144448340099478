<template>
  <div id='app' class='component-item dashboard' @mousemove='mouseMove'>

    <div v-if='isLogin'>
      <UserLogin />
    </div>

    <div v-else-if='isAuthentificationInProgress'>
      <TwoFactor />
    </div>

    <div v-else-if='isLPClientPage'>
      <PartnerLPClients />
    </div>
    <div v-else-if='ResetPasswordVerifToken'>
      <ResetPasswordVerifToken />
    </div>

    <div v-else-if='isResetPasswordTokenValid'>
      <ResetPasswordTokenValid/>
    </div>
    <div v-else-if='this.isConnectedStatus && !this.isLPClientPage'
         class='page-wrapper'>
      <Sidebar :collapsed='sidebarCollapsed'
               @toggle-collapsed='toggleSidebarCollapsed' v-if="!$route.meta.hideSideBar"/>
      <div :class="[sidebarCollapsed === true ? collapsed : '', contentWrapper]" 
      :style="[$route.meta.hideSideBar && {paddingLeft:0}]">
        <div class='main-content'>

          <Topbar :collapsed='sidebarCollapsed'
                  @toggle-collapsed='toggleSidebarCollapsed' v-if="!$route.meta.hideTopBar"/>

          <AdminTopbar v-if="$route.meta.showAdminTobar"/>

          <router-view :key='$route.fullPath'/>

        </div>
        <Footer class='footerDashboard' v-if='isDashboard'/>
      </div>

      <b-button v-if="!$route.meta.hideSideBar" v-b-modal.modalMessenger class='flyingWidgetMessenger' variant='outline-secondary' >
        <i class='icon icon-mailbox'></i>
      </b-button>

      


      <b-modal id='modalMessenger' size='xl' hide-header hide-footer>
        <Messenger name='Messagerie'/>
      </b-modal>

      

    </div>
    
    <UploadSpinner :uploadingState="uploadingState" :numFiles="numFiles"></UploadSpinner>
  
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { dropVueStorage, isDefined, tokenIsValid } from './utils';
  import { createSpinner } from '@syncfusion/ej2-popups';
  import store from '@/store';
  import { SET_DISCONNECTED } from '@/store/mutations-types';
  export default {
    name: 'App',

    components: {
      UploadSpinner: () => import(/* webpackChunkName: "UploadingSpinner" */ "./components/GED/UploadService/UploadingSpinner.vue"),
      Sidebar: () => import(/* webpackChunkName: 'Sidebar' */ './components/layout/Sidebar.vue'),
      AdminTopbar: () => import(/* webpackChunkName: 'AdminTopbar' */ './components/layout/AdminTopbar.vue'),
      Topbar: () => import(/* webpackChunkName: 'Topbar' */ './components/layout/Topbar.vue'),
      Footer: () => import(/* webpackChunkName: 'Footer' */ './components/layout/Footer.vue'),
      UserLogin: () => import(/* webpackChunkName: 'UserLogIn' */ './components/Account/UserLogIn.vue'),
      TwoFactor: () => import(/* webpackChunkName: 'TwoFactor' */ './components/Account/TwoFactor.vue'),
      PartnerLPClients: () => import(/* webpackChunkName: 'LPClients' */ './components/Partner/Dashboard/LPClients.vue'),
      Messenger: () => import(/* webpackChunkName: 'Messenger' */ './components/Messenger/Messenger.vue'),
      ResetPasswordVerifToken:()=>import(/* webpackChunkName: "ResetPasswordVerifToken" */ './components/Account/ResetPasswordVerifToken.vue'),
      ResetPasswordTokenValid:()=>import(/* webpackChunkName: "ResetPasswordTokenValid" */ './components/Account/ResetPasswordTokenValid.vue'),
      //ChatBotInterface:()=> import(/* webpackChunkName: "ChatInterface" */ './components/BotInterface/ChatInterface.vue')
    },

    data() {
      return {
        sidebarCollapsed: true,
        contentWrapper: 'content-wrapper',
        collapsed : 'collapsed',
      }
    },

    mounted() {
      createSpinner({
        target: document.getElementById('app')
      });
    },

    created() {
      // Messenger's data for Mercure
      // this.listenConversation()
      // this.listenMessage()

      //GED's data for Mercure
      this.listenFolderUpdates()
      this.listenFileSharing()
      this.listenFileUpdates()
      this.listenTodoTaskUpdates()

      //Project's data for Mercure
      this.listenFolderUpdatesSafe()
      this.listenFileUpdatesSafe()
    },

    computed: {
      ...mapState({
        isConnected: 'isConnected',
        dataIsLoaded: 'dataIsLoaded',
        authenticationInProgress: 'authenticationInProgress',
      }),
      ...mapState('uploadSys',['numFiles','uploadingState']),

      isDashboard() {
        return this.$route.name === 'Dashboard' || this.$route.name === 'Dashboard Partner'
      },

      isConnectedStatus() {
        let bool = false
        if (isDefined(this.isConnected)) {
          bool = this.isConnected
        }
        return bool
      },

      isLPClientPage() {
        return this.$route.name === 'LPClients'
      },
      
      ResetPasswordVerifToken(){
        return this.$route.name==='ResetPasswordVerifToken'
      },

      isResetPasswordTokenValid(){
        return this.$route.name==='ResetPasswordTokenValid'
      },

      isAuthentificationInProgress(){
        return this.$route.name === 'TwoFactor'
      },

      isLogin() {
        return this.$route.name === 'Login'
      },
    },

    methods: {
      ...mapActions('ged', {
        listenFolderUpdates: 'listenFolderUpdates',
        listenFileUpdates: 'listenFileUpdates',
        listenFileSharing: 'listenFileSharing',
        listenTodoTaskUpdates: 'listenTodoTaskUpdates'
      }),

      ...mapActions('messenger', {
        listenMessage: 'listenMessage',
        listenConversation: 'listenConversation'
      }),

      ...mapActions('project', {
        listenFolderUpdatesSafe: 'listenFolderUpdatesSafe',
        listenFileUpdatesSafe: 'listenFileUpdatesSafe'
      }),

      toggleSidebarCollapsed () {
        this.sidebarCollapsed = !this.sidebarCollapsed
      },

      mouseMove() {
        tokenIsValid()
        if (!tokenIsValid()) {
          store.commit(SET_DISCONNECTED)
          dropVueStorage()
        }
      }
    },
  }
</script>

<style>
/**
 * IMPORT CSS SYNCFUSION
 * @type {AxiosInstance}
 */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-vue-grids/styles/material.css';
/**
 * IMPORT CSS SYNCFUSION
 * @type {AxiosInstance}
 */

.e-spinner-pane {
  background-color: rgba(0,0,0,0.2);
  z-index: 2000;
}

</style>