import axiosRequest from "../../AuthenticatedRoute";
import { buildFormData } from "@/utils";
import { getImprovisedTaskView ,getImprovisedTaskFormat} from "@/utils/utilsTasks";
import { TasksType } from "@/utils/constants";
const state={
    improvisedTaskName:null,
    allImprovisedTasks:[],
    currentImprovisedTask:null,
    typeOfImprovisedTasks: TasksType,
    updatingImprovisedTaskForm:null,
    improvisedTasksViewByUser:[],
    filteredImprovisedTasks:[],
    teamsChosen:[]
}

const getters={
    
    getAllImprovisedTasksViewByUser(state){
        var improvisedTasks=getImprovisedTaskView(state.allImprovisedTasks)
        // var sortedTasks=improvisedTasks.sort((a,b)=>{
        //     return new Date(b.startDate)-new Date(a.startDate)
        // })
        
        var sortedTasks=improvisedTasks.filter(element=>{
            if((element.status==='En cours'|| element.status==='En attente' )&& element.duration==='00 : 00'){
                return element
            }
            else if(element.duration!=='00 : 00'){
                return element
            }
        })
        let usersImprovisedTasks={}
        for(const element of sortedTasks){
            if(element.responsible in usersImprovisedTasks){
                var tempArr=usersImprovisedTasks[element.responsible]
                tempArr.push(element)
                usersImprovisedTasks[element.responsible]=tempArr
            }
            else{
        
                usersImprovisedTasks[element.responsible]=[element]
            }
        }
        return usersImprovisedTasks
    }
}

const mutations={
    setFilteredImprovisedTasks(state,{rootState}){
        var emailOfRespo=null
        if(rootState!=null || rootState!=undefined){
            emailOfRespo=rootState.account.userInfos.email
        }
        else{
            emailOfRespo=this.state['account']['userInfos']['email']
        }
        var temp=this.getters['improvisedTask/getAllImprovisedTasksViewByUser']
        var tempArr=[]
        for (const [key, ] of Object.entries(temp)) {
             tempArr.push({[key]:temp[key]})
        }
        //the improvisedTasksView is an object where the key is the name of responsible for the tasks and the value is the tasks themselves.
        //so the array would be a [{responsibleName:[task1,task2]},{responsibleName2:[task1,task2]]
        state.filteredImprovisedTasks= tempArr.filter(element=>{
          if (emailOfRespo===Object.values(element)[0][0].responsibleEmail
            || state.teamsChosen.includes(Object.values(element)[0][0].teamName)){
            return true
          }
          return false
        })
        
    },
    setChosenTeams(state,teams){
        state.teamsChosen=teams
    },
    addInChosenTeams(state,team){
        var teamName=team.value
        if(state.teamsChosen.filter(element=>element==teamName).length<1){
            state.teamsChosen.push(teamName)
        }
    },
    deleteInChosenTeams(state,team){
        var teamName=team.text
        state.teamsChosen=state.teamsChosen.filter(element=>element!=teamName)
    },
    setBuildFormForUpdatingTask(state,obj){
        if(obj.inScheduleCase){
            const offset = obj.endDate.getTimezoneOffset();
            const cetOffset = -120; // CET offset is 2 hours ahead of UTC

            obj.endDate.setTime(obj.endDate.getTime() + (offset - cetOffset) * 60 * 1000);
        }
     
        state.updatingImprovisedTaskForm=JSON.stringify(obj)
        //state.updatingImprovisedTaskForm.endDate=new Date(state.updatingImprovisedTaskForm.endDate).toLocaleDateString('fr-FR',{timeZone: 'Europe/Paris'})
    },
    setImprovisedTaskName(state,task){
        state.improvisedTaskName=task
    },
    addImprovisedTask(state,task){
        if(state.allImprovisedTasks.filter(element=>element.id===task.id).length === 0){
            state.allImprovisedTasks.push(task) 
        }
    },
    setAllImrpovisedTasks(state,tasks){
        if(tasks){
            // state.allImprovisedTasks=tasks.filter(element=>{
            //     return element.minutesWorked!=0
            // })
            state.allImprovisedTasks=tasks
        }
        else{
            state.allImprovisedTasks=[]
        }
    },
    setCurrentImprovisedTask(state,task){
        state.currentImprovisedTask=task
    },
    updateImprovisedTasksInState(state,task){
        if(Object.prototype.hasOwnProperty.call(task,'stopwatchCase')){
            if(state.allImprovisedTasks.filter(element=>element.id===task.id).length === 0){
                state.allImprovisedTasks.push(task)
            }
        }
        state.allImprovisedTasks=state.allImprovisedTasks.map((element)=>{
            if(element.id===task.id){
                return task
            }
            return element
        })
    },
    deleteImprovisedTaskInState(state,task){
        state.allImprovisedTasks=state.allImprovisedTasks.filter(element=>element.id!==task.idTask)
    },
    setStoppedChronoToTask(state,task){
        state.allImprovisedTasks=state.allImprovisedTasks.map(element=>{
            if(element.id==task.id){
                return task
            }
            return element
        })
    },
    setTasksNull(state){
        state.improvisedTasksViewByUser=[]
        state.teamsChosen=[]
        state.filteredImprovisedTasks=[]
        state.improvisedTasksViewByUser=[]
    }
}

const actions={
    createImprovisedTask({rootState,state,commit}){
        const data={
            project_id:rootState.project.project.slug,
            type:state.improvisedTaskName
        }

        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'POST',
                url:'/project/sub/improvised-task/',
                data:buildFormData(data),
                headers:{
                    'Content-Type': 'multipart/form-data'
                },
            }).then(reponse=>{
                const responseData=reponse.data.data
                commit('stopWatchModule/setCurrentTaskOfStopWatch',responseData,{root:true})
                commit('addImprovisedTask',responseData)
                commit('setFilteredImprovisedTasks',{rootState})
                const data=getImprovisedTaskFormat(responseData)
                commit('setCurrentImprovisedTask',data)
                //dispatch('validTimeModule/addImprovisedTask',data,{root:true})
                resolve(reponse)
            }).catch(error=>{
                reject(error)
            })
        })
    },

    readImprovisedTask({rootState,state}){
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'GET',
                url:'/project/sub/improvised-task/view/'+state.currentImprovisedTask.idTask,
                header:{Authorization:'Bearer '+rootState.account.userInfos.token}
            }).then(reponse=>{
                resolve(reponse)
            }   
            ).catch(error=>{
                reject(error)
            })
        })
    },

    updateImprovisedTask({rootState,state,commit,dispatch},validatedCase=null){
        //var dateInMilliseconds=new Date(state.updatingImprovisedTaskForm).getTime()
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'PUT',
                url:'/project/sub/improvised-task/'+state.currentImprovisedTask.idTask,
                header:{
                    Authorization:'Bearer '+rootState.account.userInfos.token,
                
                },
                data:state.updatingImprovisedTaskForm
            }).then(reponse=>{
                    //console.log('old data',state.updatingImprovisedTaskForm)
                    const data=getImprovisedTaskFormat(reponse.data.data)
                    //console.log(data,'new data?')
                    //console.log(reponse.data.data,'new data2')
                    commit('updateImprovisedTasksInState',reponse.data.data)
                    commit('setFilteredImprovisedTasks',{rootState})
                    // console.log('data improvised task',data)
                  
                    if(validatedCase){
                        dispatch('validTimeModule/updateImprovisedTask',data,{root:true})

                    }
                    else if(data.status==='Validé'){
                        //dispatch('validTimeModule/updateImprovisedTask',data,{root:true})
                        dispatch('validTimeModule/addImprovisedTask',data,{root:true})

                    }
                    //commit('setCurrentImprovisedTask',null) CHANGE
                    resolve(reponse)
                }
            ).catch(error=>{
                reject(error)
            })
        })
    },

    deleteImprovisedTask({rootState,state,commit,dispatch}){
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'DELETE',
                url:'/project/sub/improvised-task/'+state.currentImprovisedTask.idTask,
                header:{Authorization:'Bearer '+rootState.account.userInfos.token}
            }).then(reponse=>{
                commit('deleteImprovisedTaskInState',state.currentImprovisedTask)
                commit('setFilteredImprovisedTasks',{rootState})
                dispatch('validTimeModule/deleteImprovisedTask',state.currentImprovisedTask,{root:true})
                commit('setCurrentImprovisedTask',null)
                resolve(reponse)
            }).catch(error=>{
                reject(error)
            })
        })
    },

    loadImprovisedTasks({rootState,commit}){
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'GET',
                // url:'/project/sub/improvised-task/list/'+rootState.project.current_project_slug,
                url:'/project/sub/improvised-task/list/'+rootState.project.project.slug,
                header:{Authorization:'Bearer '+rootState.account.userInfos.token}
            }).then(reponse=>{
                
                const data=reponse.data.data
                commit('setAllImrpovisedTasks',data)
                commit('setFilteredImprovisedTasks',{rootState})
                
                resolve(reponse)
            }).catch(error=>{
                reject(error)
            })
        })
    },
    addTeamAction({rootState,commit},teamObj){
        commit('addInChosenTeams',teamObj)
        commit('setFilteredImprovisedTasks',{rootState})
    },
    deleteTeamAction({rootState,commit},teamObj){
        commit('deleteInChosenTeams',teamObj)
        commit('setFilteredImprovisedTasks',{rootState})
    },
    setTasksNullInState({commit}){
        commit('setTasksNull')
    }
}

export default{
    namespaced:true,
    state,getters,mutations,actions
}
