import Vue from 'vue';
import Vuex from 'vuex';
import account from './modules/account';
import ged from './modules/ged';
import messenger from './modules/messenger';
import crm from './modules/crm';
import project from './modules/project';
import news from './modules/news';
import todos from "./modules/todos";
import office from "./modules/office";
import billing from './modules/billing';
import lawyer from "./modules/lawyer";
import stopWatchModule from "./modules/stopWatch";
import improvisedTask from './modules/improvisedTask';
import uploadSys from './modules/uploadSys';
import profile from './modules/profile';


import {
  CREATE_ACCOUNT, LOAD_GLOBAL_DATA,
  LOAD_MEGA_SEARCH,
  AUTHENTIFICATION_IN_PROGRESS,
  SET_CONNECTED,
  SET_DISCONNECTED,
  SET_PARTNER,
  SET_SUBSCRIPTION_PLAN,
  TOGGLE_LOAD,
  TOGGLE_SPINNER
} from "./mutations-types";

import VuexPersistence from "vuex-persist";
import { isDefined } from "@/utils";
// import { axiosRequest } from "../main";
import axiosRequest from "../AuthenticatedRoute";
import eventModule from './modules/events.js'
import userModule from './modules/tempRegistr.js'
import validTimeModule from './modules/validTime'
import disbursement from './modules/disbursement';

const vuexLocal = new VuexPersistence({
  //storage: window.localStorage,
  storage: window.sessionStorage,
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    profile,
    account,
    crm,
    ged,
    messenger,
    project,
    news,
    todos,
    billing,
    office,
    lawyer,
    eventModule,
    userModule,
    stopWatchModule,
    improvisedTask,
    validTimeModule,
    disbursement,
    uploadSys, 
  },

  state: {
    loadStatus: '',
    activeModule: '',
    isConnected: false,
    megaSearchData: [],
    dataIsLoaded: false,
    authenticationInProgress: false,
    isPartner: false,
    searchInputToolBar: '',
    subscriptionPlan: null,
    load: false,
    spinner: false
  },

  mutations: {
    [AUTHENTIFICATION_IN_PROGRESS](state){
        state.authenticationInProgress = true
    },

    [SET_CONNECTED](state){
        state.isConnected = true
    },

    [SET_DISCONNECTED](state){
      state.isPartner = ''
      state.authenticationInProgress = false
      state.isConnected = false
      state.dataIsLoaded = false
    },

    [SET_PARTNER](state){
      state.isPartner = true
    },

    [SET_SUBSCRIPTION_PLAN](state, plan){
      state.subscriptionPlan = plan
    },

    [CREATE_ACCOUNT](state){
      state.loadStatus = 'loading'
    },

    [LOAD_MEGA_SEARCH](state, data){
      state.megaSearchData = []
      state.megaSearchData = data
    },

    [LOAD_GLOBAL_DATA](state, data){
      data
      state.dataIsLoaded = true
    },

    [TOGGLE_LOAD](state){
      state.load = !state.load
    },

    [TOGGLE_SPINNER](state){
      state.spinner = !state.spinner
    }
  },

  actions: {
    disconnect({commit}){
      commit(SET_DISCONNECTED)
    },

    megaSearch({commit}, {query}){
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'GET',
          url: '/mega-search/search?q='+query
        }).then(response => {
          const data = response.data.data

          if (isDefined(data)) {
            commit(LOAD_MEGA_SEARCH, data)
          }

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    toggleDataLoaded({commit}){
      commit(LOAD_GLOBAL_DATA)
    },

    toggleLoad({commit}) {
      commit(TOGGLE_LOAD)
    },

    toggleSpinner({commit}) {
      commit(TOGGLE_SPINNER)
    },
  },

  plugins: [vuexLocal.plugin]
})

