/**
 * THE DIFFERENT LP_CLIENT OFFERS
 */
export const 
    LEGAL_SUCCESS = 'LEGALSUCCESS',
    LEGAL_TEAMS = 'LEGALTEAMS'

/**
 * Mercure events
 */
export const
    MERCURE_CREATE_EVENT = 'CREATE',
    MERCURE_UPDATE_EVENT = 'UPDATE',
    // MERCURE_UPDATE_EVENT = 'edit',
    MERCURE_DELETE_EVENT = 'DELETE',
    MERCURE_ADDFAV_EVENT = 'ADDFAV',
    MERCURE_REMOVEFAV_EVENT = 'REMOVEFAV',
    MERCURE_ADD_EVENT = 'add'

/**
 * Mercure messenger topic constants
 */
export const
    BASE_MESSAGE_TOPIC = 'MSG_MESSAGES',
    BASE_CONVERSATION_TOPIC = 'MESSENGER_CONVERSATIONS'


/**
 * Mercure messenger topic constants
 */
export const
    MERCURE_FILES_TOPIC = 'GED_FILES',
    MERCURE_FOLDERS_TOPIC = 'GED_FOLDERS',
    MERCURE_FILES_TOPIC_PROJECT = 'PROJECT_FOLDERS',
    MERCURE_TODO_TASK_TOPIC = 'TODO_TASK',
    MERCURE_PROJECT_NOTIFICATION_TOPIC = 'PROJECT_NOTIFICATIONS'


export const 
    MERCURE_SHARING_TOPIC = "SHARING_FILES_AND_FOLDERS"

export const
    FOLDER_PATH_SEPARATOR = '$',
    MESSENGER_SEPARATOR = ',',
    PERSONAL = 1,
    COMPANY = 2,
    CONTENT = 3,
    PROJECT = 3,
    MESSENGER_SIDEBAR = 4,
    MESSENGER_CONTENT = 5;

//Modules names
export const
    GED = "GED",
    MESSENGER = "MESSENGER",
    CRM = "CRM"

export const avatarColors = [
    // "#000033",
    // "#000066",
    "#000099",
    "#0000cc",
    "#0000ff",
    // "#003300",
    // "#003333",
    // "#003366",
    // "#003399",
    // "#0033cc",
    // "#0033ff",
    // "#006600",
    // "#006633",
    // "#006666",
    // "#006699",
    // "#0066cc",
    // "#0066ff",
    "#009900",
    // "#009933",
    // "#009966",
    "#009999",
    "#0099cc",
    "#0099ff",
    "#00cc00",
    // "#00cc33",
    // "#00cc66",
    // "#00cc99",
    "#00cccc",
    "#00ccff",
    "#00ff00",
    // "#00ff33",
    // "#00ff66",
    // "#00ff99",
    "#00ffcc",
    "#00ffff",
    // "#330000",
    // "#330033",
    // "#330066",
    // "#330099",
    // "#3300cc",
    // "#3300ff",
    // "#333300",
    // "#333333",
    // "#333366",
    // "#333399",
    // "#3333cc",
    // "#3333ff",
    // "#336600",
    // "#336633",
    // "#336666",
    // "#336699",
    // "#3366cc",
    // "#3366ff",
    // "#339900",
    // "#339933",
    // "#339966",
    "#339999",
    // "#3399cc",
    // "#3399ff",
    // "#33cc00",
    // "#33cc33",
    // "#33cc66",
    // "#33cc99",
    // "#33cccc",
    // "#33ccff",
    // "#33ff00",
    // "#33ff33",
    // "#33ff66",
    // "#33ff99",
    // "#33ffcc",
    // "#33ffff",
    // "#360000",
    // "#360033",
    // "#360066",
    // "#360099",
    // "#3600cc",
    // "#3600ff",
    // "#363300",
    // "#363333",
    // "#363366",
    // "#363399",
    // "#3633cc",
    // "#3633ff",
    // "#366600",
    // "#366633",
    // "#366666",
    // "#366699",
    // "#3666cc",
    // "#3666ff",
    // "#369900",
    // "#369933",
    // "#369966",
    // "#369999",
    // "#3699cc",
    // "#3699ff",
    // "#36cc00",
    // "#36cc33",
    // "#36cc66",
    // "#36cc99",
    // "#36cccc",
    // "#36ccff",
    // "#36ff00",
    // "#36ff33",
    // "#36ff66",
    // "#36ff99",
    // "#36ffcc",
    // "#36ffff",
    "#990000",
    // "#990033",
    "#990066",
    // "#990099",
    "#9900cc",
    "#9900ff",
    // "#993300",
    // "#993333",
    // "#993366",
    // "#993399",
    // "#9933cc",
    // "#9933ff",
    // "#996600",
    // "#996633",
    // "#996666",
    // "#996699",
    // "#9966cc",
    // "#9966ff",
    "#999900",
    // "#999933",
    "#999966",
    // "#999999",
    "#9999cc",
    "#9999ff",
    "#99cc00",
    // "#99cc33",
    // "#99cc66",
    // "#99cc99",
    // "#99cccc",
    // "#99ccff",
    "#99ff00",
    // "#99ff33",
    // "#99ff66",
    // "#99ff99",
    // "#99ffcc",
    // "#99ffff",
    "#cc0000",
    // "#cc0033",
    // "#cc0066",
    // "#cc0099",
    "#cc00cc",
    "#cc00ff",
    // "#cc3300",
    // "#cc3333",
    // "#cc3366",
    // "#cc3399",
    // "#cc33cc",
    // "#cc33ff",
    // "#cc6600",
    "#cc6633",
    // "#cc6666",
    "#cc6699",
    "#cc66cc",
    "#cc66ff",
    "#cc9900",
    // "#cc9933",
    // "#cc9966",
    // "#cc9999",
    // "#cc99cc",
    // "#cc99ff",
    "#cccc00",
    // "#cccc33",
    // "#cccc66",
    // "#cccc99",
    // "#cccccc",
    // "#ccccff",
    "#ccff00",
    // "#ccff33",
    // "#ccff66",
    // "#ccff99",
    // "#ccffcc",
    // "#ccffff",
    "#ff0000",
    // "#ff0033",
    "#ff0066",
    "#ff0099",
    "#ff00cc",
    "#ff00ff",
    // "#ff3300",
    // "#ff3333",
    // "#ff3366",
    // "#ff3399",
    // "#ff33cc",
    // "#ff33ff",
    "#ff6600",
    // "#ff6633",
    "#ff6666",
    "#ff6699",
    "#ff66cc",
    "#ff66ff",
    "#ff9900",
    // "#ff9933",
    // "#ff9966",
    "#ff9999",
    "#ff99cc",
    "#ff99ff",
    "#ffcc00",
    // "#ffcc33",
    // "#ffcc66",
    // "#ffcc99",
    // "#ffcccc",
    // "#ffccff",
    // "#ffff00",
    // "#ffff33",
    // "#ffff66",
    // "#ffff99",
    // "#ffffcc",
];

export const listLanguages = [
    'Chinois mandarin',
    'Espagnol',
    'Anglais',
    'Hindi /Ourdou',
    'Arabe',
    'Bengali',
    'Portugais',
    'Russe',
    'Panjabi',
    'Japonais',
    'Javanais',
    'Malais',
    'Coréen',
    'Français',
    'Allemand',
    'Chinois cantonais',
    'Turc',
    'Vietnamien',
    'Tamoul',
    'Italien',
    'Persan',
    'Thaï',
    'Polonais',
    'Birman',
    'Ukrainien',
    'Bhojpouri',
    'Filipino (tagalog)',
    'Ouzbek',
    'Amharique',
    'Roumain',
    'Néerlandais',
    'Kurde',
    'Malgache',
    'Khmer',
    'Turkmène',
    'Somali',
    'Grec',
    'Kazakh',
    'Zoulou',
    'Tchèque',
    'Créole haïtien'
];

export const languageCountryCode = {
    'Chinois mandarin': 'CN',
    'Espagnol': 'ES',
    'Anglais': 'GB',
    'Hindi /Ourdou': 'IN',
    'Arabe': 'SA',
    'Bengali': 'BD',
    'Portugais': 'BR',
    'Russe': 'RU',
    'Panjabi': 'IN',
    'Japonais': 'JP',
    'Javanais': 'ID',
    'Malais': 'MY',
    'Coréen': 'KR',
    'Français': 'FR',
    'Allemand': 'DE',
    'Chinois cantonais': 'CN',
    'Turc': 'TR',
    'Vietnamien': 'VN',
    'Tamoul': 'IN',
    'Italien': 'IT',
    'Persan': 'IR',
    'Thaï': 'TH',
    'Polonais': 'PL',
    'Birman': 'MM',
    'Ukrainien': 'UA',
    'Bhojpouri': 'IN',
    'Filipino (tagalog)': 'PH',
    'Ouzbek': 'UZ',
    'Amharique': 'ET',
    'Roumain': 'RO',
    'Néerlandais': 'NL',
    'Kurde': 'TR',
    'Malgache': 'MG',
    'Khmer': 'KH',
    'Turkmène': 'TM',
    'Somali': 'SO',
    'Grec': 'GR',
    'Kazakh': 'KZ',
    'Zoulou': 'ZA',
    'Tchèque': 'CZ',
    'Créole haïtien': 'HT'
};